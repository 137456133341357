import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedEvento } from "../../../shared/sharedEvento"

export namespace apiCallIG_Eventos_UpdateEventoUbicacion {
	export interface params {
		idEvento: number
		idCircuito: number | null
		idCircuitoPista: number | null
		lugarEnElMapaLatLon: string | null
		lugarDescripcion: string
	}
	export const paramsName = {
		idEvento: "idEvento",
		idCircuito: "idCircuito",
		idCircuitoPista: "idCircuitoPista",
		lugarEnElMapaLatLon: "lugarEnElMapaLatLon",
		lugarDescripcion: "lugarDescripcion",
	}
	export const paramsDesc = {
		idEvento: "Id evento",
		idCircuito: "Id circuito",
		idCircuitoPista: "Id circuito pista",
		lugarEnElMapaLatLon: "Lugar en el mapa (lat, lon)",
		lugarDescripcion: "Lugar descripción",
	}

	export interface IRes {
		sharedEvento: sharedEvento.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//lugarDescripcion
		apiValidations2.validateMaxNumberOfChars(
			paramsName.lugarDescripcion,
			paramsDesc.lugarDescripcion,
			params.lugarDescripcion,
			100,
			serverModelErrors
		)

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Eventos/UpdateEventoUbicacion`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedEvento: sharedEvento.getSharedOne_FromApiObject(apiCallRes) }
	}
}
