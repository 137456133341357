import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilDate } from "../../utils/utilDate"

export namespace sharedGrupoDePersonasPersona {
	export interface IShared {
		id: number
		idGrupoDePersonas: number
		idPersona: number
		rol: number
		personaAceptada: boolean
		personaAcepto: boolean
		disclaimerFirmadoHtml: string
		firmaDisclaimerURL: string
		nombrePersona: string
		apellido1Persona: string
		apellido2Persona: string
		avatarURLPersona: string
	}

	export const objectDefault: IShared = {
		id: 0,
		idGrupoDePersonas: 0,
		idPersona: 0,
		rol: 0,
		personaAceptada: false,
		personaAcepto: false,
		disclaimerFirmadoHtml: "",
		firmaDisclaimerURL: "",
		nombrePersona: "",
		apellido1Persona: "",
		apellido2Persona: "",
		avatarURLPersona: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}

	export const listForRadioGroup = (
		sharedGrupoDePersonasPersonaList: IShared[]
	): globalInterfaces.IValueNumberLabelString[] => {
		return sharedGrupoDePersonasPersonaList.map((sharedGrupoDePersonasPersona) => {
			return {
				value: sharedGrupoDePersonasPersona.idPersona,
				label: sharedGrupoDePersonasPersona.nombrePersona,
			}
		})
	}
}
