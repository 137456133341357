/* eslint-disable react/jsx-pascal-case*/
import isString from "lodash/isString"
import { useDropzone } from "react-dropzone"
// @mui
import { Typography, CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"
// type
import { SharedUploadUploadProps } from "./SharedUploadTypes"
//
import RejectionFiles from "./SharedUploadRejectionFiles"
import SharedBasicImage from "../Basic/SharedBasicImage"
import SharedBasicIconify from "../Basic/SharedBasicIconify"
import { utilLogger } from "../../../utils/utilLogger"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
	margin: "auto",
	padding: theme.spacing(1),
	border: `1px dashed ${theme.palette.grey[500_32]}`,
}))

const DropZoneStyle = styled("div")({
	zIndex: 0,
	width: "100%",
	height: "100%",
	outline: "none",
	display: "flex",
	overflow: "hidden",
	position: "relative",
	alignItems: "center",
	justifyContent: "center",
	"& > *": { width: "100%", height: "100%" },
	"&:hover": {
		cursor: "pointer",
		"& .placeholder": {
			zIndex: 9,
		},
	},
})

const PlaceholderStyle = styled("div")(({ theme }) => ({
	display: "flex",
	position: "absolute",
	alignItems: "center",
	flexDirection: "column",
	justifyContent: "center",
	color: theme.palette.text.secondary,
	backgroundColor: theme.palette.background.neutral,
	transition: theme.transitions.create("opacity", {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	"&:hover": { opacity: 0.72 },
}))

// ----------------------------------------------------------------------

export default function SharedUploadImage({
	error,
	file,
	helperText,
	isLoading,
	sxRoot,
	isCircled,
	width,
	height,
	hideSubirImagen,
	...other
}: SharedUploadUploadProps) {
	const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
		multiple: false,
		...other,
	})

	return (
		<>
			<RootStyle
				sx={{
					...((isDragReject || error) && {
						borderColor: "error.light",
					}),
					...(isCircled && { borderRadius: "50%" }),
					width: width,
					height: height,
					...sxRoot,
				}}
			>
				<DropZoneStyle
					{...getRootProps()}
					sx={{
						...(isDragActive && !isLoading && { opacity: 0.72 }),
						...(isCircled && { borderRadius: "50%" }),
					}}
				>
					<input disabled={isLoading} {...getInputProps()} />

					{file && <SharedBasicImage alt="avatar" src={isString(file) ? file : file.preview} sx={{ zIndex: 8 }} />}

					{isLoading && (
						<PlaceholderStyle
							className="placeholder"
							sx={{
								...{
									color: "common.white",
									bgcolor: "grey.900",
									opacity: 0.72,
									zIndex: 10,
								},
							}}
						>
							<CircularProgress color="inherit" />

							{/* <Typography variant="caption">Guardando...</Typography> */}
						</PlaceholderStyle>
					)}

					{!isLoading && (
						<PlaceholderStyle
							className="placeholder"
							sx={{
								...(file && {
									opacity: 0,
									color: "common.white",
									bgcolor: "grey.900",
									"&:hover": { opacity: 0.72 },
								}),
								...((isDragReject || error) && {
									bgcolor: "error.lighter",
								}),
							}}
						>
							<SharedBasicIconify iconifyIcon={"ic:round-add-a-photo"} sx={{ width: 24, height: 24, mb: 1 }} />
							{hideSubirImagen !== true && (
								<Typography variant="caption">{file ? "Subir imagen" : "Subir imagen"}</Typography>
							)}
						</PlaceholderStyle>
					)}
				</DropZoneStyle>
			</RootStyle>

			{helperText && helperText}

			{fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
		</>
	)
}
