/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useServerState from "../../../hooks/useServerState"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import EventosShared_CardList from "./EventosShared_CardList"
import useRouting from "../../../hooks/useRouting"
import { sharedTiposDeEventos } from "../../../api/shared/sharedTiposDeEventos"

interface IProps {}

export default function EventosShared_AllEventos({}: IProps) {
	const eventos = useSelector((state: IState) => state.GlobalsGeneralData.AllEventos.eventos)

	const { tabName_FromUrl } = useRouting()
	console.log("🚀 ~ EventosShared_AllEventos ~ tabName_FromUrl:", tabName_FromUrl)

	const sharedTipoEvento = sharedTiposDeEventos.getShared_FromDbDescription(tabName_FromUrl || "")

	const eventosFiltered = eventos.filter((evento) => evento.idTipoEvento === sharedTipoEvento.id)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				{/* <CardHeader title="Eventos" sx={{ mb: 3 }} />
				<EventosGlobals_Table
					eventos={eventos}
					showAll={showAll}
					showAllAppPathPage={globalPathsApp.pagesEnum.EventosLists}
					showAllPathParameters={globalPathsApp.pageParameters.EventosLists("allCircuits")}
				/> */}

				<EventosShared_CardList eventos={eventosFiltered} />
			</Card>
		</Container>
	)
}
