import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { sharedGrupoDePersonas } from "../../../shared/sharedGrupoDePersonas"

export namespace apiCallIG_GruposDePersonas_GetGrupoDePersonasById {
	export interface params {
		idGrupoDePersonas: number
	}
	export const paramsName = {
		idGrupoDePersonas: "idGrupoDePersonas",
	}
	export const paramsDesc = {
		idGrupoDePersonas: "Id grupo de personas",
	}

	export interface IRes {
		sharedGrupoDePersonas: sharedGrupoDePersonas.IShared
	}

	// const validations = async (apiCallParams: apiCallInterface, params: bodyParams) => {
	// 	const serverModelErrors: IServerModelErrors = {}

	// 	return await dispatchServerModelErrors(key, serverModelErrors)
	// }

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		// if (await validations(apiCallParams, params)) {
		// 	return null
		// }

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/GruposDePersonas/GetGrupoDePersonasById`
		const sharedParamsQuery: params | null = params
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedGrupoDePersonas: sharedGrupoDePersonas.getSharedOne_FromApiObject(apiCallRes),
		}
	}
}
