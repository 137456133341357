import { useSelector } from "react-redux"
import { sharedChatRoom } from "../api/shared/sharedChatRoom"
import { IState } from "../bll/bllReducers"
import useRouting from "../hooks/useRouting"
import useGeneral from "./useGeneral"
import { globalPathsApp } from "../globals/globalPathsApp"
import { sharedChatType } from "../api/shared/sharedChatType"
import { utilLogger } from "../utils/utilLogger"
import useGlobalsUser from "./useGlobalsUser"

export default function useChatGrupo() {
	const { ...ug } = useGeneral("useChatGrupo")
	const { currentPersona } = useGlobalsUser()

	const loadMessagesFinished = useSelector((state: IState) => state.ChatGrupo.All.loadMessagesFinished)
	const sharedChatMessages = useSelector((state: IState) => state.ChatGrupo.All.sharedChatMessagesGrupo)
	const sharedChatRoomGrupo = useSelector((state: IState) => state.ChatGrupo.All.sharedChatRoomGrupo)

	// utilLogger.two(sharedChatRooms, "sharedChatRooms")
	// utilLogger.two(sharedChatMessages, "sharedChatMessages")

	// function getRoomsUnreadForQr(rooms: sharedChatRoom.IShared[], idQr: string, idPerson: string) {
	// 	const roomsFiltered = rooms.filter((x) => x.idQr === idQr)

	// 	const roomsUnread = roomsFiltered.filter((x) => isChatRoomUnread(x, idPerson))

	// 	return roomsUnread.length
	// }

	return {
		loadMessagesFinished,
		sharedChatRoomGrupo,
		sharedChatMessages,
	}
}
