/* eslint-disable react/jsx-pascal-case*/
import { FormHelperText, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { globalAssets } from "../../../globals/globalAssets"
import useServerStateField from "../../../hooks/useServerStateField"
import { utilAws } from "../../../utils/utilAws"
import { utilLogger } from "../../../utils/utilLogger"
import { utilNumbers } from "../../../utils/utilNumbers"
import SharedUploadFile from "../Upload/SharedUploadFile"
import { SharedUploadUploadProps } from "../Upload/SharedUploadTypes"

interface IProps extends Omit<SharedUploadUploadProps, "file" | "height" | "width"> {
	callerKey: string
	fieldName: string
	label: string
	awsFolder: globalAssets.awsFolders
	fileUrl: string | null
	justIcon?: boolean
	videoIcon?: boolean
	handleOnChangeProp(newValue: string | null): void
	maxSizeFile?: number
	fileChanged?: boolean
}

export default function SharedFormUploadFile({
	callerKey,
	fieldName,
	label,
	awsFolder,
	fileUrl,
	justIcon,
	videoIcon,
	handleOnChangeProp,
	maxSizeFile = 3145728,
	fileChanged,
	...other
}: IProps) {
	const serverStateField = useServerStateField(callerKey, fieldName)

	const [errorUploading, set_errorUploading] = useState("")

	useEffect(() => {
		set_errorUploading("")
	}, [callerKey])

	// const handleOnBlur = () => {
	// 	triggerValueChanged()
	// }

	// const handleOnKeyPress = (e: any) => {
	// 	if (e.key === "Enter") {
	// 		triggerValueChanged()
	// 	}
	// }

	// const triggerValueChanged = () => {
	// 	const newValue = textboxValue.trim()
	// 	if (newValue === "" && returnNullIfEmpty) {
	// 		handleOnChangeProp(null)
	// 	} else {
	// 		handleOnChangeProp(newValue)
	// 	}
	// }

	// const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
	// 	const tempTextboxValue = e.target.value
	// 	set_textboxValue(tempTextboxValue)
	// }

	const handleDrop = useCallback(async (acceptedFiles) => {
		set_errorUploading("")

		const file = acceptedFiles[0]

		utilLogger.two(file, "file")

		if (file) {
			const fileNameWithoutExtension = uuidv4()
			const fileExtension = file.name.split(".").pop() || ""

			const fileUploadedUrl = await utilAws.uploadFile_WithFile(
				callerKey,
				file,
				awsFolder,
				fileNameWithoutExtension,
				fileExtension
			)

			if (!fileUploadedUrl) {
				set_errorUploading(
					"Ha ocurrido un error el subir el archivo. El mismo ya fue reportado, intenta nuevamente más tarde."
				)

				return
			}

			handleOnChangeProp(fileUploadedUrl)
		}
	}, [])

	return (
		<div>
			<SharedUploadFile
				error={!!serverStateField.error || !!errorUploading}
				{...other}
				file={fileUrl}
				// accept="*.*"
				isLoading={serverStateField.isLoading || serverStateField.uploadIsLoading}
				onDrop={handleDrop}
				maxSize={maxSizeFile}
				fileChanged={fileChanged}
				justIcon={justIcon}
				videoIcon={videoIcon}
				helperText={
					<Typography
						variant="caption"
						sx={{
							mx: "auto",
							display: "block",
							textAlign: "center",
							color: "text.secondary",
						}}
					>
						Tamaño máximo: {utilNumbers.fData(maxSizeFile)}
					</Typography>
				}
			/>

			{!!serverStateField.error && (
				<FormHelperText error sx={{ px: 2, textAlign: "center" }}>
					{serverStateField.error}
				</FormHelperText>
			)}

			{!!errorUploading && (
				<FormHelperText error sx={{ px: 2, textAlign: "center" }}>
					{errorUploading}
				</FormHelperText>
			)}
		</div>
	)
}
