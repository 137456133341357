import produce from "immer"
import { sharedChatMessage } from "../../api/shared/sharedChatMessage"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import { utilRedux } from "../../utils/utilRedux"
import { apiCallIG_Chat_GetOrCreateAndGetRoom } from "../../api/calls/IG/Chat/apiCallIG_Chat_GetOrCreateAndGetRoom"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Chat_SetChatRoom_AsRead } from "../../api/calls/IG/Chat/apiCallIG_Chat_SetChatRoom_AsRead"
import { apiCallIG_Chat_LoadRooms_ForPerson } from "../../api/calls/IG/Chat/apiCallIG_Chat_LoadRooms_ForPerson"
import { apiCallIG_Chat_LoadMessages_ForPerson } from "../../api/calls/IG/Chat/apiCallIG_Chat_LoadMessages_ForPerson"
import { sharedChatMessageGrupo } from "../../api/shared/sharedChatMessageGrupo"
import { apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo } from "../../api/calls/IG/ChatGrupo/apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo"
import { sharedChatRoomGrupo } from "../../api/shared/sharedChatRoomGrupo"
import { apiCallIG_ChatGrupo_SetChatRoom_AsRead } from "../../api/calls/IG/ChatGrupo/apiCallIG_ChatGrupo_SetChatRoom_AsRead"
import { apiCallIG_ChatGrupo_LoadMessagesGrupo_ForGrupoDePersonas } from "../../api/calls/IG/ChatGrupo/apiCallIG_ChatGrupo_LoadMessagesGrupo_ForGrupoDePersonas"

export namespace bllChatGrupo_All {
	//ET = enumTypes
	enum ET {
		setInitialState = "Chat_All_setInitialState",
		loadMessagesGrupo_ForGrupoDePersonas_Start = "ChatGrupo_All_loadMessagesGrupo_ForGrupoDePersonas_Start",
		loadMessagesGrupo_ForGrupoDePersonas_Finish = "ChatGrupo_All_loadMessagesGrupo_ForGrupoDePersonas_Finish",
		startChat = "Chat_All_startChat",
		addChatMessageGrupo = "Chat_Room_addChatMessageGrupo",
		setChatMessagesGrupo = "Chat_Room_setChatMessagesGrupo",
		setChatRoomGrupo = "Chat_Room_setChatRoomGrupo",
	}

	export interface IInterface {
		loadMessagesFinished: boolean
		sharedChatMessagesGrupo: sharedChatMessageGrupo.IShared[]
		sharedChatRoomGrupo: sharedChatRoomGrupo.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			loadMessagesFinished: false,
			sharedChatMessagesGrupo: [],
			sharedChatRoomGrupo: sharedChatRoomGrupo.defaultObject,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			case ET.addChatMessageGrupo:
				return produce(state, (draft) => {
					const chatMessageIndex = draft.sharedChatMessagesGrupo.findIndex(
						(chatMessageGrupo) => chatMessageGrupo.id === action.sharedChatMessagesGrupo.id
					)
					console.log("🚀 ~ returnproduce ~ chatMessageIndex:", chatMessageIndex)

					if (chatMessageIndex === -1) {
						draft.sharedChatMessagesGrupo.push(action.sharedChatMessagesGrupo)
					}
				})
				break

			// case ET.User_Login_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function setChatMessages(sharedChatMessagesGrupo: sharedChatMessageGrupo.IShared[]) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "sharedChatMessagesGrupo">
			const updateObject: actionSubType = {
				sharedChatMessagesGrupo: sharedChatMessagesGrupo,
			}

			utilRedux.TA(D, ET.setChatMessagesGrupo, updateObject)
		}
	}

	export function setChatRoomGrupo(sharedChatRoomGrupo: sharedChatRoomGrupo.IShared) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "sharedChatRoomGrupo">
			const updateObject: actionSubType = {
				sharedChatRoomGrupo: sharedChatRoomGrupo,
			}

			utilRedux.TA(D, ET.setChatRoomGrupo, updateObject)
		}
	}

	export function addChatMessage(sharedChatMessagesGrupo: sharedChatMessageGrupo.IShared) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.addChatMessageGrupo, { sharedChatMessagesGrupo })
		}
	}

	export function GetOrCreateAndGetRoom(
		apiCallParams: apiCallInterface,
		params: apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setChatRoomGrupo(apiCallRes.chatRoomGrupo))
			}
		}
	}

	export function setChatRoomAsRead(
		apiCallParams: apiCallInterface,
		params: apiCallIG_ChatGrupo_SetChatRoom_AsRead.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_ChatGrupo_SetChatRoom_AsRead.apiCall(apiCallParams, params)
			if (apiCallRes) {
				D(setChatRoomGrupo(apiCallRes.chatRoomGrupo))
			}
		}
	}

	export function loadMessagesGrupo_ForGrupoDePersonas(
		apiCallParams: apiCallInterface,
		params: apiCallIG_ChatGrupo_LoadMessagesGrupo_ForGrupoDePersonas.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "loadMessagesFinished">
			const updateObject: actionSubType = {
				loadMessagesFinished: false,
			}
			utilRedux.TA(D, ET.loadMessagesGrupo_ForGrupoDePersonas_Start, updateObject)

			const apiCallRes = await apiCallIG_ChatGrupo_LoadMessagesGrupo_ForGrupoDePersonas.apiCall(apiCallParams, params)
			if (apiCallRes) {
				type actionSubType = Pick<
					IInterface,
					"sharedChatMessagesGrupo" | "loadMessagesFinished" | "sharedChatRoomGrupo"
				>
				const updateObject: actionSubType = {
					sharedChatMessagesGrupo: apiCallRes.chatMessagesGrupo,
					sharedChatRoomGrupo: apiCallRes.chatRoomGrupo,
					loadMessagesFinished: true,
				}

				utilRedux.TA(D, ET.loadMessagesGrupo_ForGrupoDePersonas_Finish, updateObject)
			}
		}
	}
}
