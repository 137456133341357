import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedEvento } from "../../../shared/sharedEvento"

export namespace apiCallIG_Eventos_CreateEvento {
	export interface params {
		idTipoEvento: number
		idPersona: number
		idCircuito: number | null
		fechaInicio: Date
		fechaFin: Date
		nombre: string
		descripcionCorta: string | null
		crearPostEnPerfilDePersona: boolean
		crearNotificacionParaSeguidoresDePersona: boolean
		idGrupoDePersonas: number | null
		idPersonasParaNuevoGrupoDePersonas: number[] | null
	}
	export const paramsName = {
		idTipoEvento: "idTipoEvento",
		idPersona: "idPersona",
		idCircuito: "idCircuito",
		fechaInicio: "fechaInicio",
		fechaFin: "fechaFin",
		nombre: "nombre",
		descripcionCorta: "descripcionCorta",
		crearPostEnPerfilDePersona: "crearPostEnPerfilDePersona",
		crearNotificacionParaSeguidoresDePersona: "crearNotificacionParaSeguidoresDePersona",
		idGrupoDePersonas: "idGrupoDePersonas",
		idPersonasParaNuevoGrupoDePersonas: "idPersonasParaNuevoGrupoDePersonas",
	}
	export const paramsDesc = {
		idTipoEvento: "Id tipo de evento",
		idPersona: "Id persona",
		idCircuito: "Circuito",
		fechaInicio: "Fecha de inicio",
		fechaFin: "Fecha de fin",
		nombre: "Nombre",
		descripcionCorta: "Descripción corta",
		crearPostEnPerfilDePersona: "Crear post en perfil",
		crearNotificacionParaSeguidoresDePersona: "Crear notificación para seguidores",
		idGrupoDePersonas: "Grupo",
		idPersonasParaNuevoGrupoDePersonas: "Personas del grupo",
	}

	export interface IRes {
		sharedEvento: sharedEvento.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//nombre
		apiValidations2.validateMaxNumberOfChars(
			paramsName.nombre,
			paramsDesc.nombre,
			params.nombre,
			100,
			serverModelErrors
		)

		//descripcionCorta
		if (params.descripcionCorta) {
			apiValidations2.validateMaxNumberOfChars(
				paramsName.descripcionCorta,
				paramsDesc.descripcionCorta,
				params.descripcionCorta,
				250,
				serverModelErrors
			)
		}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Eventos/CreateEvento`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedEvento: sharedEvento.getSharedOne_FromApiObject(apiCallRes),
		}
	}
}
