import { combineReducers } from "redux"
import { bllGlobalsUser_CurrentPersona } from "./bllGlobalsUser_CurrentPersona"
import { bllGlobalsUser_CurrentPersonaCircuitos } from "./bllGlobalsUser_CurrentPersonaCircuitos"
import { bllGlobalsUser_CurrentPersonaEmpresas } from "./bllGlobalsUser_CurrentPersonaEmpresas"
import { bllGlobalsUser_CurrentPersonaEscuelas } from "./bllGlobalsUser_CurrentPersonaEscuelas"
import { bllGlobalsUser_CurrentPersonaMotoclubs } from "./bllGlobalsUser_CurrentPersonaMotoclubs"
import { bllGlobalsUser_CurrentPersonaFederaciones } from "./bllGlobalsUser_CurrentPersonaFederaciones"
import { bllGlobalsUser_CurrentPersonaOrganizadores } from "./bllGlobalsUser_CurrentPersonaOrganizadores"
import { bllGlobalsUser_CurrentPersonaRodadas } from "./bllGlobalsUser_CurrentPersonaRodadas"
import { bllGlobalsUser_Personas } from "./bllGlobalsUser_Personas"
import { bllGlobalsUser_General } from "./bllGlobalsUser_General"
import { bllGlobalsUser_CurrentPersonaRodadasSeguidas } from "./bllGlobalsUser_CurrentPersonaRodadasSeguidas"
import { bllGlobalsUser_CurrentPersonaCircuitosSeguidos } from "./bllGlobalsUser_CurrentPersonaCircuitosSeguidos"

import { bllGlobalsUser_CurrentPersonaEmpresasSeguidos } from "./bllGlobalsUser_CurrentPersonaEmpresasSeguidos"
import { bllGlobalsUser_CurrentPersonaEscuelasSeguidos } from "./bllGlobalsUser_CurrentPersonaEscuelasSeguidos"
import { bllGlobalsUser_CurrentPersonaMotoclubsSeguidos } from "./bllGlobalsUser_CurrentPersonaMotoclubsSeguidos"
import { bllGlobalsUser_CurrentPersonaFederacionesSeguidos } from "./bllGlobalsUser_CurrentPersonaFederacionesSeguidos"
import { bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos } from "./bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos"

import { bllGlobalsUser_Roles } from "./bllGlobalsUser_Roles"
import { bllGlobalsUser_CurrentPersonaPersonasSeguidas } from "./bllGlobalsUser_CurrentPersonaPersonasSeguidas"
import { bllGlobalsUser_CurrentPersonaEventos } from "./bllGlobalsUser_CurrentPersonaEventos"
import { bllGlobalsUser_CurrentPersonaGruposDePersonas } from "./bllGlobalsUser_CurrentPersonaGruposDePersonas"

export default combineReducers({
	CurrentPersona: bllGlobalsUser_CurrentPersona.reducerFunction,
	CurrentPersonaCircuitos: bllGlobalsUser_CurrentPersonaCircuitos.reducerFunction,
	CurrentPersonaEmpresas: bllGlobalsUser_CurrentPersonaEmpresas.reducerFunction,
	CurrentPersonaEscuelas: bllGlobalsUser_CurrentPersonaEscuelas.reducerFunction,
	CurrentPersonaMotoclubs: bllGlobalsUser_CurrentPersonaMotoclubs.reducerFunction,
	CurrentPersonaFederaciones: bllGlobalsUser_CurrentPersonaFederaciones.reducerFunction,
	CurrentPersonaOrganizadores: bllGlobalsUser_CurrentPersonaOrganizadores.reducerFunction,
	CurrentPersonaEventos: bllGlobalsUser_CurrentPersonaEventos.reducerFunction,
	CurrentPersonaGruposDePersonas: bllGlobalsUser_CurrentPersonaGruposDePersonas.reducerFunction,
	CurrentPersonaRodadas: bllGlobalsUser_CurrentPersonaRodadas.reducerFunction,
	Personas: bllGlobalsUser_Personas.reducerFunction,
	General: bllGlobalsUser_General.reducerFunction,
	CurrentPersonaRodadasSeguidas: bllGlobalsUser_CurrentPersonaRodadasSeguidas.reducerFunction,
	CurrentPersonaCircuitosSeguidos: bllGlobalsUser_CurrentPersonaCircuitosSeguidos.reducerFunction,
	CurrentPersonaEmpresasSeguidas: bllGlobalsUser_CurrentPersonaEmpresasSeguidos.reducerFunction,
	CurrentPersonaEscuelasSeguidas: bllGlobalsUser_CurrentPersonaEscuelasSeguidos.reducerFunction,
	CurrentPersonaMotoclubsSeguidos: bllGlobalsUser_CurrentPersonaMotoclubsSeguidos.reducerFunction,
	CurrentPersonaFederacionesSeguidas: bllGlobalsUser_CurrentPersonaFederacionesSeguidos.reducerFunction,
	CurrentPersonaOrganizadoresSeguidos: bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos.reducerFunction,
	CurrentPersonaPersonasSeguidas: bllGlobalsUser_CurrentPersonaPersonasSeguidas.reducerFunction,
	Roles: bllGlobalsUser_Roles.reducerFunction,
})

export interface IGlobalsUser {
	CurrentPersona: bllGlobalsUser_CurrentPersona.IInterface
	CurrentPersonaCircuitos: bllGlobalsUser_CurrentPersonaCircuitos.IInterface
	CurrentPersonaEmpresas: bllGlobalsUser_CurrentPersonaEmpresas.IInterface
	CurrentPersonaEscuelas: bllGlobalsUser_CurrentPersonaEscuelas.IInterface
	CurrentPersonaMotoclubs: bllGlobalsUser_CurrentPersonaMotoclubs.IInterface
	CurrentPersonaFederaciones: bllGlobalsUser_CurrentPersonaFederaciones.IInterface
	CurrentPersonaOrganizadores: bllGlobalsUser_CurrentPersonaOrganizadores.IInterface
	CurrentPersonaEventos: bllGlobalsUser_CurrentPersonaEventos.IInterface
	CurrentPersonaGruposDePersonas: bllGlobalsUser_CurrentPersonaGruposDePersonas.IInterface
	CurrentPersonaRodadas: bllGlobalsUser_CurrentPersonaRodadas.IInterface
	Personas: bllGlobalsUser_Personas.IInterface
	General: bllGlobalsUser_General.IInterface
	CurrentPersonaRodadasSeguidas: bllGlobalsUser_CurrentPersonaRodadasSeguidas.IInterface
	CurrentPersonaCircuitosSeguidos: bllGlobalsUser_CurrentPersonaCircuitosSeguidos.IInterface
	CurrentPersonaEmpresasSeguidas: bllGlobalsUser_CurrentPersonaEmpresasSeguidos.IInterface
	CurrentPersonaEscuelasSeguidas: bllGlobalsUser_CurrentPersonaEscuelasSeguidos.IInterface
	CurrentPersonaMotoclubsSeguidos: bllGlobalsUser_CurrentPersonaMotoclubsSeguidos.IInterface
	CurrentPersonaFederacionesSeguidas: bllGlobalsUser_CurrentPersonaFederacionesSeguidos.IInterface
	CurrentPersonaOrganizadoresSeguidos: bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos.IInterface
	CurrentPersonaPersonasSeguidas: bllGlobalsUser_CurrentPersonaPersonasSeguidas.IInterface

	Roles: bllGlobalsUser_Roles.IInterface
}
