import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedEstado } from "./sharedEstado"
import { sharedDireccion } from "./sharedDireccion"
import { utilDate } from "../../utils/utilDate"

export namespace sharedChatRoomGrupo {
	export interface IShared {
		id: number
		dateCreation: Date | null
		dateLastMessage: Date | null
		lastMessage: string
		idGrupoDePersonas: number
		nombreGrupoDePersonas: string
		avatarURL: string
		idPersonasLeidosSeparadosPorComa: number[]
	}

	export const defaultObject: IShared = {
		id: 0,
		dateCreation: null,
		dateLastMessage: null,
		lastMessage: "",
		idGrupoDePersonas: 0,
		nombreGrupoDePersonas: "",
		avatarURL: globalAssets.staticPaths.grupoDePersonasAvatar1,
		idPersonasLeidosSeparadosPorComa: [],
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			dateCreation: utilDate.fDateFromNet(apiObject.dateCreation),
			dateLastMessage: utilDate.fDateFromNet(apiObject.dateLastMessage),
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.grupoDePersonasAvatar1,
			idPersonasLeidosSeparadosPorComa:
				apiObject.idPersonasLeidosSeparadosPorComa?.split(",").map((x: string) => parseInt(x)) || [],
		}

		return res
	}
}
