import { utilDate } from "../../utils/utilDate"

export namespace sharedNotifASeguidores {
	export interface IShared {
		id: number
		idPersona: number
		fecha: Date
		mensaje: string
	}

	export const objectDefault: IShared = {
		id: 0,
		idPersona: 0,
		fecha: new Date(),
		mensaje: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
			fecha: utilDate.fDateFromNet(apiObject.fecha),
		}

		return res
	}
}
