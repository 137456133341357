/* eslint-disable react/jsx-pascal-case*/
import { sharedTiposDeEventos } from "../../../api/shared/sharedTiposDeEventos"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useRouting from "../../../hooks/useRouting"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import EventosShared_AllEventos from "../Shared/EventosShared_AllEventos"

export const EventosLanding_Menu = (): ISharedTabs_Tab[] => {
	const { isUserCurrentRol_Admin } = useGlobalsUserRoles()

	var menu: ISharedTabs_Tab[] = []

	menu.push({
		name: sharedTiposDeEventos.sharedEnum.rodada.dbDescripcion,
		label: "Rodadas",
		component: <EventosShared_AllEventos />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Eventos(sharedTiposDeEventos.sharedEnum.rodada.dbDescripcion),
	})

	menu.push({
		name: sharedTiposDeEventos.sharedEnum.quedada.dbDescripcion,
		label: "Quedadas",
		component: <EventosShared_AllEventos />,
		iconifyIcon: "maki:campsite",
		urlDest: globalPathsApp.pageInsideUrl.Eventos(sharedTiposDeEventos.sharedEnum.quedada.dbDescripcion),
	})

	menu.push({
		name: sharedTiposDeEventos.sharedEnum.entrenamiento.dbDescripcion,
		label: "Entrenamientos",
		component: <EventosShared_AllEventos />,
		iconifyIcon: "maki:car",
		urlDest: globalPathsApp.pageInsideUrl.Eventos(sharedTiposDeEventos.sharedEnum.entrenamiento.dbDescripcion),
	})

	return menu
}
