import { globalAssets } from "../../globals/globalAssets"
import { utilDate } from "../../utils/utilDate"

export namespace sharedEvento {
	export interface IShared {
		id: number
		idTipoEvento: number
		idGrupoDePersonas: number
		idCircuito: number | null
		idCircuitoPista: number | null
		fechaInicio: Date
		fechaFin: Date
		nombre: string
		descripcionCorta: string
		descripcionLarga: string
		lugarEnElMapaLatLon: string
		lugarDescripcion: string
		telefonoDeContacto: string
		emailDeContacto: string
		whatsappDeContacto: string
		avatarUrl: string
		coverUrl: string
		esPrivado: boolean | null
	}

	export const objectDefault: IShared = {
		id: 0,
		idTipoEvento: 0,
		idGrupoDePersonas: 0,
		idCircuito: null,
		idCircuitoPista: null,
		fechaInicio: new Date(),
		fechaFin: new Date(),
		nombre: "",
		descripcionCorta: "",
		descripcionLarga: "",
		lugarEnElMapaLatLon: "",
		lugarDescripcion: "",
		telefonoDeContacto: "",
		emailDeContacto: "",
		whatsappDeContacto: "",
		avatarUrl: globalAssets.staticPaths.eventoAvatar1,
		coverUrl: globalAssets.staticPaths.eventoCover1,
		esPrivado: false,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
			fechaInicio: utilDate.fDateFromNet(apiObject.fechaInicio),
			fechaFin: utilDate.fDateFromNet(apiObject.fechaFin),
			avatarUrl: apiObject.avatarUrl || globalAssets.staticPaths.eventoAvatar1,
			coverUrl: apiObject.coverUrl || globalAssets.staticPaths.eventoCover1,
		}

		return res
	}
}
