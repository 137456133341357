import { combineReducers } from "redux"
import { bllChatGrupo_All } from "./bllChatGrupo_All"
import { bllChatGrupo_Start } from "./bllChatGrupo_Start"

export default combineReducers({
	All: bllChatGrupo_All.reducerFunction,
	Start: bllChatGrupo_Start.reducerFunction,
})

export interface IChatGrupo {
	All: bllChatGrupo_All.IInterface
	Start: bllChatGrupo_Start.IInterface
}
