import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedEvento } from "../../api/shared/sharedEvento"
import { apiCallIG_Eventos_DeleteEvento } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_DeleteEvento"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Eventos_UpdateEventoDisclaimer } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_UpdateEventoDisclaimer"
import { apiCallIG_Eventos_GetEventoById } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_GetEventoById"
import { apiCallIG_Eventos_UpdateEventoGeneral } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_UpdateEventoGeneral"
import { apiCallIG_Eventos_UpdateEventoUbicacion } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_UpdateEventoUbicacion"
import { apiCallIG_Eventos_UpdateEventoContact } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_UpdateEventoContact"
import { apiCallIG_Eventos_UpdateEventoAvatar } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_UpdateEventoAvatar"
import { apiCallIG_Eventos_UpdateEventoCover } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_UpdateEventoCover"
import { bllGlobalsGeneralData_AllEventos } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllEventos"

export namespace bllEventos_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Eventos_Settings_setInitialState",
		loadEvento = "Eventos_Settings_loadEvento",
		updateWithSharedEvento = "Eventos_Settings_updateWithSharedEvento",
		updateEventoGeneral = "Eventos_Settings_updateEventoGeneral",
		deleteEvento = "Eventos_Settings_deleteEvento",
		updateEventoContact = "Eventos_Settings_updateEventoContact",
		updateEventoUbicacion = "Eventos_Settings_updateEventoUbicacion",
		updateEventoAvatar = "Eventos_Settings_updateEventoAvatar",
		updateEventoCover = "Eventos_Settings_updateEventoCover",
	}

	export interface IInterface {
		evento: sharedEvento.IShared
		isDeleted: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			evento: sharedEvento.objectDefault,
			isDeleted: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadEvento(apiCallParams: apiCallInterface, params: apiCallIG_Eventos_GetEventoById.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Eventos_GetEventoById.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "evento">
				const updateObject: actionSubType = {
					evento: apiCallRes.sharedEvento,
				}

				utilRedux.TA(D, ET.loadEvento, updateObject)
			}
		}
	}

	export function updateEventoWithSharedEvento(apiCallParams: apiCallInterface, sharedEvento: sharedEvento.IShared) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "evento">
			const updateObject: actionSubType = {
				evento: sharedEvento,
			}

			utilRedux.TA(D, ET.updateWithSharedEvento, updateObject)
		}
	}

	export function updateEventoGeneral(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Eventos_UpdateEventoGeneral.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Eventos_UpdateEventoGeneral.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEventos_Settings.updateEventoWithSharedEvento(apiCallParams, apiCallRes.sharedEvento))

				utilRedux.TA(D, ET.updateEventoGeneral, {})
			}
		}
	}

	export function updateEventoAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Eventos_UpdateEventoAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Eventos_UpdateEventoAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEventos_Settings.updateEventoWithSharedEvento(apiCallParams, apiCallRes.sharedEvento))

				utilRedux.TA(D, ET.updateEventoAvatar, {})
			}
		}
	}

	export function updateEventoCover(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Eventos_UpdateEventoCover.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Eventos_UpdateEventoCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEventos_Settings.updateEventoWithSharedEvento(apiCallParams, apiCallRes.sharedEvento))

				utilRedux.TA(D, ET.updateEventoCover, {})
			}
		}
	}

	export function updateEventoContact(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Eventos_UpdateEventoContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Eventos_UpdateEventoContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEventos_Settings.updateEventoWithSharedEvento(apiCallParams, apiCallRes.sharedEvento))

				utilRedux.TA(D, ET.updateEventoContact, {})
			}
		}
	}

	export function updateEventoUbicacion(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Eventos_UpdateEventoUbicacion.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Eventos_UpdateEventoUbicacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEventos_Settings.updateEventoWithSharedEvento(apiCallParams, apiCallRes.sharedEvento))

				utilRedux.TA(D, ET.updateEventoUbicacion, {})
			}
		}
	}

	export function deleteEvento(apiCallParams: apiCallInterface, params: apiCallIG_Eventos_DeleteEvento.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Eventos_DeleteEvento.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllEventos.loadAllEventos(apiCallParams))

				utilRedux.TA(D, ET.deleteEvento, updateObject)
			}
		}
	}
}
