import { m, MotionProps } from "framer-motion"
import { Box, BoxProps } from "@mui/material"
import { varFade } from "../../globals/globalAnimate/variants/fade"

type Props = BoxProps & MotionProps

interface TextAnimateProps extends Props {
	text: string
}

export default function AnimateText({ text, variants, sx, ...other }: TextAnimateProps) {
	return (
		<Box
			component={m.div}
			sx={{
				m: 0,
				typography: "h3",
				overflow: "hidden",
				display: "inline-flex",
				...sx,
			}}
			{...other}
		>
			{text.split("").map((letter, index) => (
				<div key={index}>
					{letter === " " ? <span>&nbsp;</span> : <m.span variants={variants || varFade().inUp}>{letter}</m.span>}
				</div>
			))}
		</Box>
	)
}
