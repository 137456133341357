import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_GruposDePersonas_ActualizarAvatar } from "../../api/calls/IG/GruposDePersonas/apiCallIG_GruposDePersonas_ActualizarAvatar"
import { apiCallIG_GruposDePersonas_ActualizarCover } from "../../api/calls/IG/GruposDePersonas/apiCallIG_GruposDePersonas_ActualizarCover"
import { apiCallIG_GruposDePersonas_GetGrupoDePersonasById } from "../../api/calls/IG/GruposDePersonas/apiCallIG_GruposDePersonas_GetGrupoDePersonasById"
import { apiCallIG_GruposDePersonas_UpdateGrupoDePersonasGeneral } from "../../api/calls/IG/GruposDePersonas/apiCallIG_GruposDePersonas_UpdateGrupoDePersonasGeneral"
import { sharedGrupoDePersonas } from "../../api/shared/sharedGrupoDePersonas"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllGruposDePersonas_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "GruposDePersonas_Settings_setInitialState",
		loadGrupoDePersonas = "GruposDePersonas_Settings_loadGrupoDePersonas",
		updateWithSharedGrupoDePersonas = "GruposDePersonas_Settings_updateWithSharedGrupoDePersonas",
		updateGrupoDePersonasGeneral = "GruposDePersonas_Settings_updateGrupoDePersonasGeneral",
		actualizarCover = "GruposDePersonas_Settings_actualizarCover",
		actualizarAvatar = "GruposDePersonas_Settings_actualizarAvatar",
	}

	export interface IInterface {
		grupoDePersonas: sharedGrupoDePersonas.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			grupoDePersonas: sharedGrupoDePersonas.objectDefault,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadGrupoDePersonas(
		apiCallParams: apiCallInterface,
		params: apiCallIG_GruposDePersonas_GetGrupoDePersonasById.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_GruposDePersonas_GetGrupoDePersonasById.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "grupoDePersonas">
				const updateObject: actionSubType = {
					grupoDePersonas: apiCallRes.sharedGrupoDePersonas,
				}

				utilRedux.TA(D, ET.loadGrupoDePersonas, updateObject)
			}
		}
	}

	export function updateGrupoDePersonasWithSharedGrupoDePersonas(
		apiCallParams: apiCallInterface,
		sharedGrupoDePersonas: sharedGrupoDePersonas.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "grupoDePersonas">
			const updateObject: actionSubType = {
				grupoDePersonas: sharedGrupoDePersonas,
			}

			utilRedux.TA(D, ET.updateWithSharedGrupoDePersonas, updateObject)
		}
	}

	export function updateGrupoDePersonasGeneral(
		apiCallParams: apiCallInterface,
		params: apiCallIG_GruposDePersonas_UpdateGrupoDePersonasGeneral.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_GruposDePersonas_UpdateGrupoDePersonasGeneral.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllGruposDePersonas_Settings.updateGrupoDePersonasWithSharedGrupoDePersonas(
						apiCallParams,
						apiCallRes.sharedGrupoDePersonas
					)
				)

				utilRedux.TA(D, ET.updateGrupoDePersonasGeneral, {})
			}
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_GruposDePersonas_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_GruposDePersonas_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateGrupoDePersonasWithSharedGrupoDePersonas(apiCallParams, apiCallRes.sharedGrupoDePersonas))

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarCover(
		apiCallParams: apiCallInterface,
		params: apiCallIG_GruposDePersonas_ActualizarCover.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_GruposDePersonas_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateGrupoDePersonasWithSharedGrupoDePersonas(apiCallParams, apiCallRes.sharedGrupoDePersonas))

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}
}
