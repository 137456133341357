import produce from "immer"
import { sharedChatMessage } from "../../api/shared/sharedChatMessage"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import { utilRedux } from "../../utils/utilRedux"
import { apiCallIG_Chat_GetOrCreateAndGetRoom } from "../../api/calls/IG/Chat/apiCallIG_Chat_GetOrCreateAndGetRoom"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Chat_SetChatRoom_AsRead } from "../../api/calls/IG/Chat/apiCallIG_Chat_SetChatRoom_AsRead"
import { apiCallIG_Chat_LoadRooms_ForPerson } from "../../api/calls/IG/Chat/apiCallIG_Chat_LoadRooms_ForPerson"
import { apiCallIG_Chat_LoadMessages_ForPerson } from "../../api/calls/IG/Chat/apiCallIG_Chat_LoadMessages_ForPerson"
import { sharedChatRoomGrupo } from "../../api/shared/sharedChatRoomGrupo"
import { apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo } from "../../api/calls/IG/ChatGrupo/apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo"

export namespace bllChatGrupo_Start {
	//ET = enumTypes
	enum ET {
		setInitialState = "Chat_Start_setInitialState",
		started = "Chat_Start_started",
	}

	export interface IInterface {
		sharedChatRoomGrupo: sharedChatRoomGrupo.IShared | null
	}

	const getInitialState = (): IInterface => {
		return {
			sharedChatRoomGrupo: null,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function setChatRoom(sharedChatRoomGrupo: sharedChatRoomGrupo.IShared) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "sharedChatRoomGrupo">
			const updateObject: actionSubType = {
				sharedChatRoomGrupo: sharedChatRoomGrupo,
			}

			utilRedux.TA(D, ET.started, updateObject)
		}
	}

	export function GetOrCreateAndGetRoomGrupo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_ChatGrupo_GetOrCreateAndGetChatRoomGrupo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setChatRoom(apiCallRes.chatRoomGrupo))
			}
		}
	}
}
