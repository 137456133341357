import { createContext } from "react"

type ContextChatGrupoType = {
	isChatConnected: boolean
	sendMessage: (message: string, idGrupoDePersonas: number) => void
}

export const ContextChatGrupo = createContext<ContextChatGrupoType>({
	isChatConnected: false,
	sendMessage: () => {},
})
