/* eslint-disable react/jsx-pascal-case*/
import { Avatar, Box, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from "@mui/material"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import useRouting from "../../hooks/useRouting"
import useChat from "../../hooksData/useChat"
import useGeneral from "../../hooksData/useGeneral"
import { utilDate } from "../../utils/utilDate"
import orderBy from "lodash/orderBy"
import { utilLogger } from "../../utils/utilLogger"
import { useEffect, useState } from "react"
import { bllChat_All } from "../../bll/Chat/bllChat_All"
import SharedBasicBadgeStatus from "../Shared/Basic/SharedBasicBadgeStatus"
import useGlobalsUser from "../../hooksData/useGlobalsUser"
import { sharedChatType } from "../../api/shared/sharedChatType"

type Props = {
	room: sharedChatRoom.IShared
	openNav: boolean
	hideRoomType?: boolean
	onSelect: VoidFunction
}

export default function Chat_Rooms_List_Item({ room, openNav, hideRoomType, onSelect }: Props) {
	const ug = useGeneral("Chat_Rooms_List_Item")
	const { currentPersona } = useGlobalsUser()

	const { idChatRoom_FromUrl, idChatRoom_Set } = useRouting()
	const { isChatRoomUnread, getRoomInfo } = useChat()

	if (!room.lastMessage && room.idPerson1 !== currentPersona.id) return null

	const isUnread = isChatRoomUnread(room, currentPersona.id) && room.id !== idChatRoom_FromUrl
	const isSelected = room.id === idChatRoom_FromUrl

	// if (!nowIsRead) {
	// 	if (isUnread) {
	// 		set_nowIsRead(true)
	// 		ug.D(bllChat_All.setChatRoomAsRead(ug.apiCallParams, { idPerson: currentPersona.id, idChatRoom: room.id }))
	// 	}
	// }

	const roomInfo = getRoomInfo(room, currentPersona.id)

	var dateToShow = utilDate.getDate_ForChat(room.dateLastMessage || null)

	const handleClick = () => {
		onSelect()
		idChatRoom_Set(room.id)
	}

	var lastMessage = room.lastMessage || ""

	if (lastMessage.startsWith("filetype:img")) {
		lastMessage = "📷 Imagen"
	} else if (lastMessage.startsWith("filetype:video")) {
		lastMessage = "🎥 Video"
	} else if (lastMessage.startsWith("filetype:doc")) {
		lastMessage = "📄 Documento"
	}

	return (
		<Box
			onClick={handleClick}
			sx={{
				pr: 1.5,
				borderTop: "1px solid #e0e0e0",
				"&:hover": {
					bgcolor: "action.hover",
					cursor: "pointer",
				},
				...(isSelected && {
					bgcolor: "action.selected",
				}),
			}}
		>
			{!!hideRoomType === false && (
				<ListItemButton
					sx={{
						"&:hover": {
							bgcolor: "transparent !important",
						},
						pb: 0,
					}}
				>
					{roomInfo.secondaryNameToShow && (
						<>
							<ListItemAvatar>
								<Avatar
									alt={roomInfo.secondaryNameToShow}
									src={roomInfo.secondaryAvatarUrlToShow}
									sx={{ width: 24, height: 24, ml: 1.5 }}
								>
									{roomInfo.secondaryNameToShow}
								</Avatar>
							</ListItemAvatar>

							{openNav && (
								<>
									<ListItemText
										primary={roomInfo.secondaryNameToShow}
										primaryTypographyProps={{
											noWrap: true,
											variant: "body2",
											// textAlign: "center",
											color: ug.theme.palette.primary.main,
											pl: 1.5,
										}}
									/>
								</>
							)}
						</>
					)}

					{/* <ListItemText
						primary={room.nameQr || gl.DirectMessage[ul]}
						primaryTypographyProps={{
							noWrap: true,
							variant: "body2",
							textAlign: "center",
							color: theme.palette.primary.main,
						}}
					/> */}
				</ListItemButton>
			)}
			<ListItemButton
				sx={{
					"&:hover": {
						bgcolor: "transparent !important",
					},
					pt: 0,
				}}
			>
				<ListItemAvatar>
					<Avatar alt={roomInfo.mainNameToShow} src={roomInfo.mainAvatarUrlToShow} sx={{ width: 48, height: 48 }}>
						{roomInfo.mainNameToShow}
					</Avatar>
				</ListItemAvatar>

				{openNav && (
					<>
						<ListItemText
							primary={roomInfo.mainNameToShow}
							primaryTypographyProps={{ noWrap: true, variant: "subtitle2" }}
							secondary={lastMessage}
							secondaryTypographyProps={{
								noWrap: true,
								variant: isUnread ? "subtitle2" : "body2",
								color: isUnread ? "text.primary" : "text.secondary",
							}}
						/>

						<Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
							<Typography
								noWrap
								variant="body2"
								component="span"
								sx={{
									mb: 1.5,
									fontSize: 12,
									color: "text.disabled",
								}}
							>
								{dateToShow}
							</Typography>

							{isUnread && <SharedBasicBadgeStatus status="unread" size="small" />}
						</Stack>
					</>
				)}
			</ListItemButton>
		</Box>
	)
}
