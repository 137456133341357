/* eslint-disable react/jsx-pascal-case*/
import { Box } from "@mui/material"
import { sharedEvento } from "../../../api/shared/sharedEvento"
import EventosGlobals_CardList_Card from "./EventosShared_CardList_Card"

interface IProps {
	eventos: sharedEvento.IShared[]
}

export default function EventosShared_CardList({ eventos }: IProps) {
	return (
		<>
			<Box
				gap={3}
				display="grid"
				sx={{ p: 3 }}
				gridTemplateColumns={{
					xs: "repeat(1, 1fr)",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				}}
			>
				{eventos.map((evento, i) => (
					<EventosGlobals_CardList_Card key={i} evento={evento} />
				))}

				{eventos.length === 0 && (
					<Box
						sx={{
							color: "text.secondary",
						}}
					>
						-Aún no hay eventos en este listado-
					</Box>
				)}
			</Box>
		</>
	)
}
