import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedCircuito } from "../../api/shared/sharedCircuito"
import { apiCallIG_Circuitos_GetAllCircuitos } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_GetAllCircuitos"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_General_InitialCall } from "../../api/calls/IG/General/apiCallIG_General_InitialCall"
import { bllGlobalsGeneralData_AllCircuitos } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllCircuitos"
import { bllGlobalsGeneralData_AllEmpresas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllEmpresas"
import { bllGlobalsGeneralData_AllEscuelas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllEscuelas"
import { bllGlobalsGeneralData_AllFederaciones } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllFederaciones"
import { bllGlobalsGeneralData_AllMotoclubs } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllMotoclubs"
import { bllGlobalsGeneralData_AllOrganizadores } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllOrganizadores"
import { bllGlobalsGeneralData_AllRodadas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllRodadas"
import { bllGlobalsGeneralData_AllVehiculos } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllVehiculos"
import { apiCallIG_General_InitialCallForPersona } from "../../api/calls/IG/General/apiCallIG_General_InitialCallForPersona"
import { bllGlobalsUser_CurrentPersonaCircuitos } from "./bllGlobalsUser_CurrentPersonaCircuitos"
import { bllGlobalsUser_CurrentPersonaEmpresas } from "./bllGlobalsUser_CurrentPersonaEmpresas"
import { bllGlobalsUser_CurrentPersonaEscuelas } from "./bllGlobalsUser_CurrentPersonaEscuelas"
import { bllGlobalsUser_CurrentPersonaFederaciones } from "./bllGlobalsUser_CurrentPersonaFederaciones"
import { bllGlobalsUser_CurrentPersonaOrganizadores } from "./bllGlobalsUser_CurrentPersonaOrganizadores"
import { bllGlobalsUser_CurrentPersonaMotoclubs } from "./bllGlobalsUser_CurrentPersonaMotoclubs"
import { bllGlobalsUser_CurrentPersonaRodadas } from "./bllGlobalsUser_CurrentPersonaRodadas"
import { bllGlobalsUser_CurrentPersonaRodadasSeguidas } from "./bllGlobalsUser_CurrentPersonaRodadasSeguidas"
import { bllGlobalsUser_CurrentPersonaCircuitosSeguidos } from "./bllGlobalsUser_CurrentPersonaCircuitosSeguidos"
import { bllGlobalsUser_CurrentPersonaEmpresasSeguidos } from "./bllGlobalsUser_CurrentPersonaEmpresasSeguidos"
import { bllGlobalsUser_CurrentPersonaEscuelasSeguidos } from "./bllGlobalsUser_CurrentPersonaEscuelasSeguidos"
import { bllGlobalsUser_CurrentPersonaFederacionesSeguidos } from "./bllGlobalsUser_CurrentPersonaFederacionesSeguidos"
import { bllGlobalsUser_CurrentPersonaMotoclubsSeguidos } from "./bllGlobalsUser_CurrentPersonaMotoclubsSeguidos"
import { bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos } from "./bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos"
import { bllGlobalsUser_CurrentPersonaPersonasSeguidas } from "./bllGlobalsUser_CurrentPersonaPersonasSeguidas"
import { bllGlobalsUser_CurrentPersonaGruposDePersonas } from "./bllGlobalsUser_CurrentPersonaGruposDePersonas"
import { bllGlobalsUser_CurrentPersonaEventos } from "./bllGlobalsUser_CurrentPersonaEventos"

export namespace bllGlobalsUser_CurrentPersona_InitialLoad {
	//ET = enumTypes
	enum ET {
		setInitialState = "GlobalsUser_CurrentPersona_InitialLoad_setInitialState",
		load = "GlobalsUser_CurrentPersona_InitialLoad_load",
	}

	export interface IInterface {
		loaded: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			loaded: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function initialCallForPersona(apiCallParams: apiCallInterface, idPersona: number) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_General_InitialCallForPersona.apiCall(apiCallParams, { idPersona: idPersona })

			if (apiCallRes) {
				await D(
					bllGlobalsUser_CurrentPersonaCircuitos.setPersonaCircuitos(
						apiCallRes.sharedInitialCallForPersonaRes.personaCircuitos
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaGruposDePersonas.setPersonaGruposDePersonas(
						apiCallRes.sharedInitialCallForPersonaRes.personaGruposDePersonas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaEventos.setPersonaEventos(
						apiCallRes.sharedInitialCallForPersonaRes.personaEventos
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaEmpresas.setPersonaEmpresas(
						apiCallRes.sharedInitialCallForPersonaRes.personaEmpresas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaEscuelas.setPersonaEscuelas(
						apiCallRes.sharedInitialCallForPersonaRes.personaEscuelas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaFederaciones.setPersonaFederaciones(
						apiCallRes.sharedInitialCallForPersonaRes.personaFederaciones
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaOrganizadores.setPersonaOrganizadores(
						apiCallRes.sharedInitialCallForPersonaRes.personaOrganizadores
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaMotoclubs.setPersonaMotoclubs(
						apiCallRes.sharedInitialCallForPersonaRes.personaMotoclubs
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaRodadas.setPersonaRodadas(
						apiCallRes.sharedInitialCallForPersonaRes.personaRodadas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaRodadasSeguidas.setPersonaRodadasSeguidas(
						apiCallRes.sharedInitialCallForPersonaRes.rodadasSeguidas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaCircuitosSeguidos.setPersonaCircuitosSeguidos(
						apiCallRes.sharedInitialCallForPersonaRes.circuitosSeguidos
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaEmpresasSeguidos.setPersonaEmpresasSeguidos(
						apiCallRes.sharedInitialCallForPersonaRes.empresasSeguidas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaEscuelasSeguidos.setPersonaEscuelasSeguidos(
						apiCallRes.sharedInitialCallForPersonaRes.escuelasSeguidas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaFederacionesSeguidos.setPersonaFederacionesSeguidos(
						apiCallRes.sharedInitialCallForPersonaRes.federacionesSeguidas
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaMotoclubsSeguidos.setPersonaMotoclubsSeguidos(
						apiCallRes.sharedInitialCallForPersonaRes.motoclubsSeguidos
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos.setPersonaOrganizadoresSeguidos(
						apiCallRes.sharedInitialCallForPersonaRes.organizadoresSeguidos
					)
				)
				await D(
					bllGlobalsUser_CurrentPersonaPersonasSeguidas.setPersonaPersonasSeguidas(
						apiCallRes.sharedInitialCallForPersonaRes.personasSeguidas
					)
				)

				type actionSubType = Pick<IInterface, "loaded">
				const updateObject: actionSubType = {
					loaded: true,
				}

				utilRedux.TA(D, ET.load, updateObject)
			}
		}
	}
}
