/* eslint-disable react/jsx-pascal-case*/

import { Button, Divider, MenuItem, Stack, Typography, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import { alpha } from "@mui/material/styles"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { bllUsers_Login } from "../../bll/Users/bllUsers_Login"
import { globalPathsApp } from "../../globals/globalPathsApp"
import SharedBasicIconButtonAnimated from "../Shared/Basic/SharedBasicIconButtonAnimated"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"
import SharedMenuPopover from "../Shared/SharedMenuPopover"
import PersonasGlobals_CurrentAvatar from "../Personas/Globals/PersonasGlobals_CurrentAvatar"
import { PersonasDelegadasDialog } from "../Personas/DelegadasDialog/PersonasDelegadasDialog"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import useGlobalsUserRoles from "../../hooksData/useGlobalsUserRoles"
import { sharedUserRoles } from "../../api/shared/sharedUserRoles"
import { bllGlobalsUser_Roles } from "../../bll/GlobalsUser/bllGlobalsUser_Roles"
import useGeneral from "../../hooksData/useGeneral"

export const LayoutAccountHeaderDropdown = () => {
	const D = useDispatch()
	const theme = useTheme()

	const ug = useGeneral("LayoutAccountHeaderDropdown")

	const { userRoles, userCurrentRol, userHasAdminRol } = useGlobalsUserRoles()

	const idPersona = useSelector((state: IState) => state.GlobalsUser.CurrentPersona.currentPersona.id)
	const personaName = useSelector((state: IState) => state.GlobalsUser.CurrentPersona.currentPersona.nombre)
	const userEmail = useSelector((state: IState) => state.Users.Login.loginRes.email)
	const personasDelegadas = useSelector((state: IState) => state.GlobalsUser.Personas.personasDelegadas)
	// const roles = useSelector((state: IState) => state.Users.Login.loginRes.roles)

	const [open, set_open] = useState<HTMLElement | null>(null)
	const [personasDelegadasDialogOpen, set_personasDelegadasDialogOpen] = useState(false)

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		set_open(event.currentTarget)
	}

	const handleClose = () => {
		set_open(null)
	}

	const handleLogout = () => {
		handleClose()
		D(bllUsers_Login.logout())
	}

	const handleChangeRolClick = (rol: sharedUserRoles.IShared) => {
		if (rol.id === userCurrentRol.id) return
		ug.D(bllGlobalsUser_Roles.setCurrentRol(rol))
	}

	return (
		<>
			<SharedBasicIconButtonAnimated
				sx={{
					p: 0,
					...(open && {
						"&:before": {
							zIndex: 1,
							content: "''",
							width: "100%",
							height: "100%",
							borderRadius: "50%",
							position: "absolute",
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
				onClick={handleOpen}
			>
				<PersonasGlobals_CurrentAvatar />
			</SharedBasicIconButtonAnimated>

			<SharedMenuPopover
				open={open}
				onClose={() => handleClose()}
				sx={{
					p: 0,
					mt: 1.5,
					ml: 0.75,
					"& .MuiMenuItem-root": {
						typography: "body2",
						borderRadius: 0.75,
					},
				}}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<SharedBasicTypo variant="subtitle2" color={theme.palette.grey[900]} noWrap>
						{personaName}
					</SharedBasicTypo>

					<SharedBasicTypo variant="body2" color={theme.palette.grey[800]} noWrap>
						{userEmail}
					</SharedBasicTypo>

					{/* {roles.length > 0 && (
						<SharedBasicTypo variant="body2" color={theme.palette.grey[800]}>
							Roles: {roles.join(", ")}
						</SharedBasicTypo>
					)} */}

					{personasDelegadas.length > 0 && (
						<Button size="small" sx={{ py: 0, px: 0 }} onClick={() => set_personasDelegadasDialogOpen(true)}>
							Cambiar de perfil
						</Button>
					)}
				</Box>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Box sx={{ my: 1.5, px: 2.5 }}>
					{/* <SharedBasicTypo variant="body2" color={theme.palette.grey[800]} noWrap>
						Roles:
					</SharedBasicTypo> */}

					{sharedUserRoles.sharedList.map((rol) => {
						if (userRoles.some((userRol) => userRol.id === rol.id) || userHasAdminRol) {
							return (
								<SharedBasicTypo
									key={rol.id}
									variant="body2"
									sx={{ cursor: "pointer" }}
									color={rol.id === userCurrentRol.id ? theme.palette.grey[800] : theme.palette.primary.main}
									noWrap
									onClick={() => handleChangeRolClick(rol)}
								>
									{rol.descripcion}
								</SharedBasicTypo>
							)
						}
					})}
				</Box>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Stack sx={{ p: 1 }}>
					<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.PersonasProfile(undefined, idPersona)}>
						<MenuItem onClick={handleClose}>{`Perfil de ${personaName}`}</MenuItem>
					</SharedLinkInsideAppWrapper>
					<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.PersonasSettings(undefined, idPersona)}>
						<MenuItem onClick={handleClose}>{`Ajustes de ${personaName}`}</MenuItem>
					</SharedLinkInsideAppWrapper>
				</Stack>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Stack sx={{ p: 1 }}>
					<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.UsersSettings()}>
						<MenuItem onClick={handleClose}>{`Ajustes de usuario`}</MenuItem>
					</SharedLinkInsideAppWrapper>
				</Stack>

				<Divider sx={{ borderStyle: "dashed" }} />

				<MenuItem onClick={handleLogout} sx={{ m: 1 }}>
					Cerrar sesión
				</MenuItem>
			</SharedMenuPopover>

			<PersonasDelegadasDialog
				isDialogOpen={personasDelegadasDialogOpen}
				close={() => set_personasDelegadasDialogOpen(false)}
			/>
		</>
	)
}
