/* eslint-disable react/jsx-pascal-case*/
import isString from "lodash/isString"
import { useDropzone } from "react-dropzone"
// @mui
import { Typography, CircularProgress, Stack, Box, ListItem, ListItemText, IconButton, List } from "@mui/material"
import { styled } from "@mui/material/styles"
// type
import { SharedUploadUploadProps } from "./SharedUploadTypes"
//
import RejectionFiles from "./SharedUploadRejectionFiles"
import SharedBasicImage from "../Basic/SharedBasicImage"
import SharedBasicIconify from "../Basic/SharedBasicIconify"
import { utilLogger } from "../../../utils/utilLogger"
import AssetUploadIllustration from "../../Assets/AssetUploadIllustration"
import { m, AnimatePresence } from "framer-motion"
import { varFade } from "../../../globals/globalAnimate/variants/fade"
import { utilNumbers } from "../../../utils/utilNumbers"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
	margin: "auto",
	padding: theme.spacing(1),
	border: `1px dashed ${theme.palette.grey[500_32]}`,
}))

const DropZoneStyle = styled("div")({
	zIndex: 0,
	width: "100%",
	height: "100%",
	outline: "none",
	display: "flex",
	overflow: "hidden",
	position: "relative",
	alignItems: "center",
	justifyContent: "center",
	"& > *": { width: "100%", height: "100%" },
	"&:hover": {
		cursor: "pointer",
		"& .placeholder": {
			zIndex: 9,
		},
	},
})

const PlaceholderStyle = styled("div")(({ theme }) => ({
	display: "flex",
	position: "absolute",
	width: "100%",
	height: "100%",
	alignItems: "center",
	flexDirection: "column",
	justifyContent: "center",
	color: theme.palette.text.secondary,
	backgroundColor: theme.palette.background.neutral,
	transition: theme.transitions.create("opacity", {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	"&:hover": { opacity: 0.72 },
}))

// ----------------------------------------------------------------------

interface IProps extends Omit<SharedUploadUploadProps, "height" | "width"> {
	justIcon?: boolean
	videoIcon?: boolean
	fileChanged?: boolean
}

export default function SharedUploadFile({
	fileChanged,
	error,
	file,
	justIcon,
	videoIcon,
	helperText,
	isLoading,
	sxRoot,
	...other
}: IProps) {
	const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
		multiple: false,
		...other,
	})

	return (
		<>
			{justIcon ? (
				<IconButton
					{...getRootProps()}
					disabled={isLoading}
					sx={{
						...(isDragActive && !isLoading && { opacity: 0.72 }),
						...sxRoot,
						height: 48,
						color: fileChanged ? "primary.main" : "text.secondary",
					}}
				>
					{fileChanged ? (
						<SharedBasicIconify iconifyIcon={videoIcon ? "mage:video-check-fill" : "mdi:attachment-check"} />
					) : (
						<SharedBasicIconify iconifyIcon={videoIcon ? "fluent:video-add-20-filled" : "akar-icons:attach"} />
					)}
				</IconButton>
			) : (
				<>
					<RootStyle
						sx={{
							...((isDragReject || error) && {
								borderColor: "error.light",
							}),
							...sxRoot,
						}}
					>
						<DropZoneStyle
							{...getRootProps()}
							sx={{
								...(isDragActive && !isLoading && { opacity: 0.72 }),
							}}
						>
							<input disabled={isLoading} {...getInputProps()} />

							<Stack
								spacing={2}
								alignItems="center"
								justifyContent="center"
								direction={{ xs: "column", md: "row" }}
								sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
							>
								{isLoading && (
									<PlaceholderStyle
										className="placeholder"
										sx={{
											...{
												color: "common.white",
												bgcolor: "grey.900",
												opacity: 0.72,
												zIndex: 10,
											},
										}}
									>
										<CircularProgress color="inherit" />

										{/* <Typography variant="caption">Guardando...</Typography> */}
									</PlaceholderStyle>
								)}

								<>
									<AssetUploadIllustration sx={{ width: 220 }} />

									<Box sx={{ p: 3 }}>
										<Typography gutterBottom variant="h5">
											Arrastra o selecciona un archivo
										</Typography>

										<Typography variant="body2" sx={{ color: "text.secondary" }}>
											Arrastra archivos aquí o&nbsp;
											<Typography
												variant="body2"
												component="span"
												sx={{ color: "primary.main", textDecoration: "underline" }}
											>
												navega
											</Typography>
											&nbsp;entre tus archivos
										</Typography>
									</Box>
								</>
							</Stack>
						</DropZoneStyle>
					</RootStyle>

					{fileChanged && (
						<>
							<List disablePadding sx={{ ...{ my: 3 } }}>
								<AnimatePresence>
									<ListItem
										component={m.div}
										{...varFade().inRight}
										sx={{
											my: 1,
											px: 2,
											py: 0.75,
											borderRadius: 0.75,
											border: (theme) => `solid 1px ${theme.palette.divider}`,
										}}
									>
										<SharedBasicIconify
											iconifyIcon={"eva:file-fill"}
											sx={{ width: 28, height: 28, color: "text.secondary", mr: 2 }}
										/>

										<ListItemText
											primary={isString(file) ? file : ""}
											secondary="Previa del archivo por subir"
											primaryTypographyProps={{ variant: "subtitle2" }}
											secondaryTypographyProps={{ variant: "caption" }}
										/>
									</ListItem>
								</AnimatePresence>
							</List>
						</>
					)}
				</>
			)}

			{justIcon !== true && helperText && helperText}

			{fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
		</>
	)
}
