import { combineReducers } from "redux"
import { bllGlobalsGeneralData_AllCircuitos } from "./bllGlobalsGeneralData_AllCircuitos"
import { bllGlobalsGeneralData_AllEmpresas } from "./bllGlobalsGeneralData_AllEmpresas"
import { bllGlobalsGeneralData_AllEscuelas } from "./bllGlobalsGeneralData_AllEscuelas"
import { bllGlobalsGeneralData_AllMotoclubs } from "./bllGlobalsGeneralData_AllMotoclubs"
import { bllGlobalsGeneralData_AllFederaciones } from "./bllGlobalsGeneralData_AllFederaciones"
import { bllGlobalsGeneralData_AllOrganizadores } from "./bllGlobalsGeneralData_AllOrganizadores"
import { bllGlobalsGeneralData_AllRodadas } from "./bllGlobalsGeneralData_AllRodadas"
import { bllGlobalsGeneralData_AllVehiculos } from "./bllGlobalsGeneralData_AllVehiculos"
import { bllGlobalsGeneralData_InitialLoad } from "./bllGlobalsGeneralData_InitialLoad"
import { bllGlobalsGeneralData_AllEventos } from "./bllGlobalsGeneralData_AllEventos"
import { bllGlobalsGeneralData_AllGruposDePersonas } from "./bllGlobalsGeneralData_AllGruposDePersonas"

export default combineReducers({
	InitialCall: bllGlobalsGeneralData_InitialLoad.reducerFunction,
	AllCircuitos: bllGlobalsGeneralData_AllCircuitos.reducerFunction,
	AllEmpresas: bllGlobalsGeneralData_AllEmpresas.reducerFunction,
	AllEscuelas: bllGlobalsGeneralData_AllEscuelas.reducerFunction,
	AllMotoclubs: bllGlobalsGeneralData_AllMotoclubs.reducerFunction,
	AllFederaciones: bllGlobalsGeneralData_AllFederaciones.reducerFunction,
	AllOrganizadores: bllGlobalsGeneralData_AllOrganizadores.reducerFunction,
	AllRodadas: bllGlobalsGeneralData_AllRodadas.reducerFunction,
	AllVehiculos: bllGlobalsGeneralData_AllVehiculos.reducerFunction,
	AllEventos: bllGlobalsGeneralData_AllEventos.reducerFunction,
	AllGruposDePersonas: bllGlobalsGeneralData_AllGruposDePersonas.reducerFunction,
})

export interface IGlobalsGeneralData {
	InitialCall: bllGlobalsGeneralData_InitialLoad.IInterface
	AllCircuitos: bllGlobalsGeneralData_AllCircuitos.IInterface
	AllEmpresas: bllGlobalsGeneralData_AllEmpresas.IInterface
	AllEscuelas: bllGlobalsGeneralData_AllEscuelas.IInterface
	AllMotoclubs: bllGlobalsGeneralData_AllMotoclubs.IInterface
	AllFederaciones: bllGlobalsGeneralData_AllFederaciones.IInterface
	AllOrganizadores: bllGlobalsGeneralData_AllOrganizadores.IInterface
	AllRodadas: bllGlobalsGeneralData_AllRodadas.IInterface
	AllVehiculos: bllGlobalsGeneralData_AllVehiculos.IInterface
	AllEventos: bllGlobalsGeneralData_AllEventos.IInterface
	AllGruposDePersonas: bllGlobalsGeneralData_AllGruposDePersonas.IInterface
}
