import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Eventos_GetEventos_ByIdPersona } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_GetEventos_ByIdPersona"
import { apiCallIG_Eventos_GetEventoById } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_GetEventoById"
import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { sharedEvento } from "../../api/shared/sharedEvento"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllGlobalsUser_CurrentPersonaEventos {
	//ET = enumTypes
	enum ET {
		setInitialState = "bllGlobalsUser_CurrentPersonaEventos_setInitialState",
		load = "bllGlobalsUser_CurrentPersonaEventos_load",
	}

	export interface IInterface {
		eventos: sharedEvento.IShared[]
		loaded: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			eventos: [],
			loaded: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Current_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadEventosDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Eventos_GetEventos_ByIdPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Eventos_GetEventos_ByIdPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setPersonaEventos(apiCallRes.sharedEventos))
			}
		}
	}

	export function setPersonaEventos(allEventos: sharedEvento.IShared[]) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "eventos" | "loaded">
			const updateObject: actionSubType = {
				eventos: allEventos,
				loaded: true,
			}

			utilRedux.TA(D, ET.load, updateObject)
		}
	}
}
