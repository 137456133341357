/* eslint-disable react/jsx-pascal-case*/
import { FormHelperText, Typography } from "@mui/material"
import imageCompression from "browser-image-compression"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { IState } from "../../../bll/bllReducers"
import { globalAssets } from "../../../globals/globalAssets"
import { utilAws } from "../../../utils/utilAws"
import { utilNumbers } from "../../../utils/utilNumbers"
import SharedUploadImage from "../Upload/SharedUploadImage"
import { SharedUploadUploadProps } from "../Upload/SharedUploadTypes"
import useServerStateField from "../../../hooks/useServerStateField"

interface IProps extends Omit<SharedUploadUploadProps, "file"> {
	callerKey: string
	fieldName: string
	label: string
	awsFolder: globalAssets.awsFolders
	fileUrl: string | null
	compressToSizeInMB: number
	hideHelperText?: boolean
	hideSubirImagen?: boolean
	handleOnChangeProp(newValue: string | null): void
}

const maxSizeImage = 3145728

export default function SharedFormUploadImage({
	callerKey,
	fieldName,
	label,
	awsFolder,
	fileUrl,
	compressToSizeInMB,
	handleOnChangeProp,
	hideHelperText,
	hideSubirImagen,
	...other
}: IProps) {
	const D = useDispatch()

	const serverStateField = useServerStateField(callerKey, fieldName)

	const [errorUploading, set_errorUploading] = useState("")

	useEffect(() => {
		set_errorUploading("")
	}, [callerKey])

	// const handleOnBlur = () => {
	// 	triggerValueChanged()
	// }

	// const handleOnKeyPress = (e: any) => {
	// 	if (e.key === "Enter") {
	// 		triggerValueChanged()
	// 	}
	// }

	// const triggerValueChanged = () => {
	// 	const newValue = textboxValue.trim()
	// 	if (newValue === "" && returnNullIfEmpty) {
	// 		handleOnChangeProp(null)
	// 	} else {
	// 		handleOnChangeProp(newValue)
	// 	}
	// }

	// const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
	// 	const tempTextboxValue = e.target.value
	// 	set_textboxValue(tempTextboxValue)
	// }

	const handleDrop = useCallback(async (acceptedFiles) => {
		set_errorUploading("")

		const file = acceptedFiles[0]

		if (file) {
			const imageCompressionOptions = {
				maxSizeMB: compressToSizeInMB,
				// maxWidthOrHeight: 1920,
				useWebWorker: true,
			}

			const compressedFile = await imageCompression(file, imageCompressionOptions)

			const fileNameWithoutExtension = uuidv4()
			const fileExtension = file.name.split(".").pop() || ""

			const fileUploadedUrl = await utilAws.uploadFile_WithFile(
				callerKey,
				compressedFile,
				awsFolder,
				fileNameWithoutExtension,
				fileExtension
			)

			if (!fileUploadedUrl) {
				set_errorUploading(
					"Ha ocurrido un error el subir la imagen. El mismo ya fue reportado, intenta nuevamente más tarde."
				)

				return
			}

			handleOnChangeProp(fileUploadedUrl)
		}
	}, [])

	return (
		<div>
			<SharedUploadImage
				error={!!serverStateField.error || !!errorUploading}
				{...other}
				file={fileUrl}
				accept="image/*"
				isLoading={serverStateField.isLoading || serverStateField.uploadIsLoading}
				onDrop={handleDrop}
				maxSize={maxSizeImage}
				hideSubirImagen={hideSubirImagen}
				helperText={
					!hideHelperText && (
						<Typography
							variant="caption"
							sx={{
								mx: "auto",
								display: "block",
								textAlign: "center",
								color: "text.secondary",
							}}
						>
							Permitidos: *.jpeg, *.jpg, *.png, *.gif
							<br /> Tamaño máximo: {utilNumbers.fData(maxSizeImage)}
						</Typography>
					)
				}
			/>

			{!!serverStateField.error && (
				<FormHelperText error sx={{ px: 2, textAlign: "center" }}>
					{serverStateField.error}
				</FormHelperText>
			)}

			{!!errorUploading && (
				<FormHelperText error sx={{ px: 2, textAlign: "center" }}>
					{errorUploading}
				</FormHelperText>
			)}
		</div>
	)
}
