/* eslint-disable react/jsx-pascal-case*/
import { Stack } from "@mui/material"
import { sharedChatMessage } from "../../api/shared/sharedChatMessage"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import useGeneral from "../../hooksData/useGeneral"
import { utilDate } from "../../utils/utilDate"
import { utilLogger } from "../../utils/utilLogger"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import useGlobalsUser from "../../hooksData/useGlobalsUser"

type Props = {
	room: sharedChatRoom.IShared
	chatMessage: sharedChatMessage.IShared
	nameOtherEntity: string
}

export default function Chat_Room_MessagesList_Item({ room, chatMessage, nameOtherEntity }: Props) {
	const ug = useGeneral("Chat_Room_MessagesList_Item")
	const { currentPersona } = useGlobalsUser()

	const sentByMe = chatMessage.idPerson1 === currentPersona.id

	// var idOtherPerson = ""
	// var nameOtherPerson = ""
	// var avatarUrlOtherPerson: string | null = ""

	// if (room.idPerson1 === ug.user_person.id) {
	// 	idOtherPerson = room.idPerson2
	// 	nameOtherPerson = room.namePerson2
	// 	avatarUrlOtherPerson = room.avatarUrlPerson2
	// } else {
	// 	idOtherPerson = room.idPerson1
	// 	nameOtherPerson = room.namePerson1
	// 	avatarUrlOtherPerson = room.avatarUrlPerson1
	// }

	return (
		<Stack direction="row" justifyContent={sentByMe ? "flex-end" : "unset"} sx={{ mb: 3 }}>
			{/* {!sentByMe && (
				<Avatar alt={nameOtherPerson} src={avatarUrlOtherPerson || undefined} sx={{ width: 32, height: 32, mr: 2 }} />
			)} */}

			<Stack spacing={1} alignItems="flex-end">
				<SharedBasicTypo
					noWrap
					variant="caption"
					sx={{
						color: ug.theme.palette.text.disabled,
						...(!sentByMe && {
							mr: "auto",
						}),
					}}
				>
					{`${sentByMe ? "tú" : nameOtherEntity},`} &nbsp;
					{utilDate.getDate_ForChat_Full(chatMessage?.dateSent || null)}
				</SharedBasicTypo>

				<Stack
					sx={{
						p: 1.5,
						minWidth: 48,
						maxWidth: 320,
						borderRadius: 1,
						overflow: "hidden",
						typography: "body2",
						bgcolor: "background.neutral",
						...(sentByMe && {
							color: "grey.800",
							bgcolor: "primary.lighter",
						}),
						...(!sentByMe && {
							mr: "auto !important",
						}),
					}}
				>
					{chatMessage.message.startsWith("filetype:img") ? (
						<img src={chatMessage.message.replace("filetype:img", "")} alt="Imagen" style={{ maxWidth: "100%" }} />
					) : chatMessage.message.startsWith("filetype:doc") ? (
						<a href={chatMessage.message.replace("filetype:doc", "")} target="_blank" rel="noreferrer">
							Ver documento
						</a>
					) : chatMessage.message.startsWith("filetype:video") ? (
						<video src={chatMessage.message.replace("filetype:video", "")} controls style={{ maxWidth: "100%" }} />
					) : (
						chatMessage.message
					)}
				</Stack>
			</Stack>
		</Stack>
	)
}
