import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_ActualizarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarPosteoDePersona"
import { apiCallIG_Personas_EliminarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_EliminarPosteoDePersona"
import { apiCallIG_Personas_AgregarPosteoParaPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_AgregarPosteoParaPersona"
import { apiCallIG_PersonasSeguidores_SeguirPersona } from "../../api/calls/IG/PersonasSeguidores/apiCallIG_PersonasSeguidores_SeguirPersona"
import { apiCallIG_PersonasSeguidores_DejarDeSeguirPersona } from "../../api/calls/IG/PersonasSeguidores/apiCallIG_PersonasSeguidores_DejarDeSeguirPersona"
import { bllGlobalsUser_CurrentPersonaPersonasSeguidas } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaPersonasSeguidas"
import { apiCallIG_Eventos_GetEventos_ByIdPersona } from "../../api/calls/IG/Eventos/apiCallIG_Eventos_GetEventos_ByIdPersona"
import { sharedEvento } from "../../api/shared/sharedEvento"
import { apiCallIG_Personas_EnviarNotificacionASeguidores } from "../../api/calls/IG/Personas/apiCallIG_Personas_EnviarNotificacionASeguidores"
import { sharedNotifASeguidores } from "../../api/shared/sharedNotifASeguidores"
import { apiCallIG_Personas_GetNotifASeguidoresByIdPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetNotifASeguidoresByIdPersona"

export namespace bllPersonas_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Personas_Profile_setInitialState",
		load = "Personas_Profile_load",
		actualizarPosteoDePersona = "Personas_Profile_actualizarPosteoDePersona",
		agregarPosteoParaPersona = "Personas_Profile_agregarPosteoParaPersona",
		eliminarPosteoDePersona = "Personas_Profile_eliminarPosteoDePersona",
		seguirPersona = "Personas_Profile_seguirPersona",
		dejarDeSeguirPersona = "Personas_Profile_dejarDeSeguirPersona",
		loadEventos = "Personas_Profile_loadEventos",
		enviarNotificacionASeguidores = "Rodadas_Profile_enviarNotificacionASeguidores",
		getNotifASeguidores = "Personas_Profile_getNotifASeguidores",
	}

	export interface IInterface {
		persona: sharedPersona.IShared
		eventos: sharedEvento.IShared[]
		notificacionEnviada: boolean
		notifASeguidores: sharedNotifASeguidores.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			persona: sharedPersona.objectDefault,
			eventos: [],
			notificacionEnviada: false,
			notifASeguidores: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getNotifASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_GetNotifASeguidoresByIdPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_GetNotifASeguidoresByIdPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notifASeguidores">
				const updateObject: actionSubType = {
					notifASeguidores: apiCallRes.sharedNotifASeguidoresList,
				}

				utilRedux.TA(D, ET.getNotifASeguidores, updateObject)
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Personas_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}

	export function loadPersona(apiCallParams: apiCallInterface, params: apiCallIG_Personas_GetPersona.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Personas_GetPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "persona">
				const updateObject: actionSubType = {
					persona: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.load, updateObject)
			}
		}
	}

	export function actualizarPosteoDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarPosteoDePersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarPosteoDePersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "persona">
				const updateObject: actionSubType = {
					persona: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.actualizarPosteoDePersona, updateObject)
			}
		}
	}

	export function eliminarPosteoDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_EliminarPosteoDePersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_EliminarPosteoDePersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "persona">
				const updateObject: actionSubType = {
					persona: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.eliminarPosteoDePersona, updateObject)
			}
		}
	}

	export function agregarPosteoParaPersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_AgregarPosteoParaPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_AgregarPosteoParaPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "persona">
				const updateObject: actionSubType = {
					persona: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.agregarPosteoParaPersona, updateObject)
			}
		}
	}

	export function seguirPersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_PersonasSeguidores_SeguirPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_PersonasSeguidores_SeguirPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaPersonasSeguidas.setPersonaPersonasSeguidas(apiCallRes.sharedPersonasSeguidas))

				type actionSubType = Pick<IInterface, "persona">
				const updateObject: actionSubType = {
					persona: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.seguirPersona, updateObject)
			}
		}
	}

	export function dejarDeSeguirPersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_PersonasSeguidores_DejarDeSeguirPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_PersonasSeguidores_DejarDeSeguirPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaPersonasSeguidas.setPersonaPersonasSeguidas(apiCallRes.sharedPersonasSeguidas))

				type actionSubType = Pick<IInterface, "persona">
				const updateObject: actionSubType = {
					persona: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.dejarDeSeguirPersona, updateObject)
			}
		}
	}

	export function loadEventos(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Eventos_GetEventos_ByIdPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Eventos_GetEventos_ByIdPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "eventos">
				const updateObject: actionSubType = {
					eventos: apiCallRes.sharedEventos,
				}

				utilRedux.TA(D, ET.loadEventos, updateObject)
			}
		}
	}
}
