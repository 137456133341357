import { utilLogger } from "../../utils/utilLogger"

export namespace sharedTiposDeEventos {
	export interface IShared {
		id: number
		dbDescripcion: string
		descripcion: string
	}

	export const sharedEnum = {
		noDefinido: { id: 0, dbDescripcion: "NoDefinido", descripcion: "No definido" },
		rodada: { id: 1, dbDescripcion: "Rodada", descripcion: "Rodada" },
		quedada: { id: 2, dbDescripcion: "Quedada", descripcion: "Quedada" },
		entrenamiento: { id: 3, dbDescripcion: "Entrenamiento", descripcion: "Entrenamiento" },
	}

	export const sharedList: IShared[] = [sharedEnum.rodada, sharedEnum.quedada, sharedEnum.entrenamiento]

	// export const userHasRoleAdmin = (userRoles: IShared[]) => {
	// 	const t = userIsInRole(userRoles, sharedEnum.admin)
	// 	return t
	// }

	// export const userHasRoleSaludOnNet = (userRoles: IShared[]) => {
	// 	return userIsInRole(userRoles, sharedEnum.saludOnNet)
	// }

	// export const userHasRoleOrganizadorDeCircuito = (userRoles: IShared[]) => {
	// 	return userIsInRole(userRoles, sharedEnum.organizadorDeCircuito)
	// }

	// export const userHasRoleColaborador = (userRoles: IShared[]) => {
	// 	return userIsInRole(userRoles, sharedEnum.colaborador)
	// }

	export const getShared_FromDbDescription = (dbDescription: string) => {
		const sharedObj = sharedList.find((obj) => obj.dbDescripcion === dbDescription)

		if (sharedObj) return sharedObj

		utilLogger.error(
			`Error en sharedUserRoles.getShared_FromDbDescription. Vino un dbDescription inexistente.`,
			dbDescription
		)

		return sharedEnum.noDefinido
	}

	export const getShared_FromId = (id: number) => {
		const sharedObj = sharedList.find((obj) => obj.id === id)

		if (sharedObj) return sharedObj

		utilLogger.error(`Error en sharedUserRoles.getShared_FromId. Vino un id inexistente.`, id)

		return sharedEnum.noDefinido
	}
}
