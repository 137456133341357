import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { sharedChatMessage } from "../../api/shared/sharedChatMessage"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import { bllChat_All } from "../../bll/Chat/bllChat_All"
import { IState } from "../../bll/bllReducers"
import { globalConfig } from "../../globals/globalConfig"
import useGeneral from "../../hooksData/useGeneral"
import useGlobalsUser from "../../hooksData/useGlobalsUser"
import { utilLogger } from "../../utils/utilLogger"
import { ContextChat } from "./ContextChat"
import useChat from "../../hooksData/useChat"
import { utilApiCalls } from "../../utils/utilApiCalls"
import useChatGrupo from "../../hooksData/useChatGrupo"
import { sharedChatMessageGrupo } from "../../api/shared/sharedChatMessageGrupo"
import { sharedChatRoomGrupo } from "../../api/shared/sharedChatRoomGrupo"
import { bllChatGrupo_All } from "../../bll/ChatGrupo/bllChatGrupo_All"
import { ContextChatGrupo } from "./ContextChatGrupo"

const connectionLoggingLevel = globalConfig.environment !== "production" ? LogLevel.Information : LogLevel.None

export const ContextChatGrupoProvider: React.FC = ({ children }) => {
	const { apiCallParams, D, userToken } = useGeneral("useChatGrupoSocket")
	const { currentPersona } = useGlobalsUser()
	// const secondLoadFinished = useSelector((state: IState) => state.GlobalsGeneral.InitialLoad.secondLoadFinished)

	// const { user_token, user_bdCode } = useGlobalsUser()

	const { loadMessagesFinished, sharedChatRoomGrupo: current_sharedChatRoomGrupo } = useChatGrupo()

	const [connection, set_connection] = useState<HubConnection | null>(null)
	const [isChatConnected, set_isChatConnected] = useState(false)
	const [headersAsQueryParameters, set_headersAsQueryParameters] = useState("")

	useEffect(() => {
		if (connection && connection.state === "Connected") {
			utilLogger.two("useChatGrupoSocket", "connection already connected")

			connection.off("ReceiveMessage")

			connection.on("ReceiveMessage", (user, socketMessage) => {
				console.log("🚀 ~ connection.on ~ socketMessage:", socketMessage)
				const chatMessageGrupo = sharedChatMessageGrupo.getSharedOne_FromApiObject(socketMessage.chatMessageGrupo)
				const chatRoomGrupo = sharedChatRoomGrupo.getSharedOne_FromApiObject(socketMessage.chatRoomGrupo)

				D(bllChatGrupo_All.addChatMessage(chatMessageGrupo))
				D(bllChatGrupo_All.setChatRoomGrupo(chatRoomGrupo))
			})
		}
	}, [connection?.state, apiCallParams, isChatConnected, D])

	useEffect(() => {
		// if (user_token) {
		set_headersAsQueryParameters(
			utilApiCalls.getHeadersAsQueryParameters({
				// userToken: user_token,
				// bdCode: user_bdCode,
			})
		)
		// }
	}, [])

	const sendMessage = (message: string, idGrupoDePersonas: number) => {
		if (connection && connection.state === "Connected" && sharedChatRoomGrupo) {
			connection.invoke("SendMessage", {
				idPersona: currentPersona.id,
				idGrupoDePersonas: idGrupoDePersonas,
				message,
			})
		}
	}

	const createConnection = (headersAsQueryParameters: string, _userToken: string) => {
		if (!_userToken) return
		const newConnection = new HubConnectionBuilder()
			// .withUrl(`${globalConfig.apiPathBaseGeneral}/ChatHub${headersAsQueryParameters}`)
			// .withUrl(`${globalConfig.apiPathBaseGeneral}/ChatHub`)
			//with url and add token
			.withUrl(`${globalConfig.apiPathBaseGeneral}/ChatGrupoHub`, {
				accessTokenFactory: () => {
					return _userToken
				},
			})
			.configureLogging(connectionLoggingLevel)
			.build()

		// hubConnection = new HubConnectionBuilder()
		//   .WithUrl(NavigationManager.ToAbsoluteUri("/broadcaster"), options =>
		//   {
		//       options.AccessTokenProvider = async () =>
		//       {
		//           var accessTokenResult = await AccessTokenProvider.RequestAccessToken();
		//           accessTokenResult.TryGetToken(out var accessToken);
		//           return accessToken.Value;
		//       };
		//   })
		//   .Build();

		set_connection(newConnection)
	}

	const connect_ifItIsDisconnected = (connection: HubConnection | null) => {
		utilLogger.two(connection, "connection")
		if (connection && connection.state === "Disconnected") {
			connection
				.start()
				.then(() => {
					utilLogger.two("", "Connected (grupo)!")

					set_isChatConnected(true)

					connection.onclose((e) => {
						utilLogger.two(e, "Connection closed (grupo)!")
						set_isChatConnected(false)
					})
				})
				.catch((e) => {
					utilLogger.two(e, "Error al conectar con el socket (grupo)")
				})
		}
	}

	const disconnect = () => {
		if (connection && connection.state === "Connected") {
			connection
				.stop()
				.then(() => {
					// utilLogger.two("", "Disconnected (grupo)!")
					set_isChatConnected(false)
				})
				.catch((e) => {
					utilLogger.two(e, "Error al desconectar con el socket (grupo)")
				})
		}
	}

	useEffect(() => {
		if (loadMessagesFinished && current_sharedChatRoomGrupo) {
			connect_ifItIsDisconnected(connection)
		}
	}, [connection, currentPersona, loadMessagesFinished, current_sharedChatRoomGrupo])

	useEffect(() => {
		if (
			connection &&
			connection.state === "Connected" &&
			loadMessagesFinished &&
			current_sharedChatRoomGrupo &&
			currentPersona &&
			currentPersona.id !== 0
		) {
			connection
				.invoke("Join", {
					idGrupoDePersonas: current_sharedChatRoomGrupo.idGrupoDePersonas,
					idPersona: currentPersona.id,
				})
				.then(() => {
					// utilLogger.two("", "Joined group (grupo)!" + current_sharedChatRoomGrupo.idGrupoDePersonas)
				})
				.catch((e) => {
					utilLogger.two(e, "Error al unirse al grupo (grupo)")
				})
		}
	}, [connection?.state, currentPersona, loadMessagesFinished, current_sharedChatRoomGrupo])

	useEffect(() => {
		if (!isChatConnected /*&& secondLoadFinished*/ && headersAsQueryParameters !== "f") {
			createConnection(headersAsQueryParameters, userToken)
		}

		return () => {
			disconnect()
		}
	}, [isChatConnected, /*secondLoadFinished,*/ headersAsQueryParameters, userToken])

	return <ContextChatGrupo.Provider value={{ isChatConnected, sendMessage }}>{children}</ContextChatGrupo.Provider>
}
