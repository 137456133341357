/* eslint-disable react/jsx-pascal-case*/
import { IconButton, InputAdornment, InputBase, InputBaseProps, Stack } from "@mui/material"
import { useState } from "react"
import useChat from "../../hooksData/useChat"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import useGeneral from "../../hooksData/useGeneral"
import useGlobalsUser from "../../hooksData/useGlobalsUser"
import SharedFormUploadFile from "../Shared/Form/SharedFormUploadFile"
import { globalAssets } from "../../globals/globalAssets"
import SharedFormUploadImage from "../Shared/Form/SharedFormUploadImage"

interface Props extends InputBaseProps {
	onSend: (message: string) => void
}

export default function Chat_Room_Input({ disabled, onSend, sx, ...other }: Props) {
	const ug = useGeneral("Chat_Room_Input")
	const { currentPersona } = useGlobalsUser()

	const { currentRoom_sharedChatRoom } = useChat()

	const [message, set_message] = useState("")
	const [imagenUrl, set_imagenUrl] = useState<string | null>(null)
	const [documentoUrl, set_documentoUrl] = useState<string | null>(null)
	const [videoUrl, set_videoUrl] = useState<string | null>(null)

	const inputDisabled = disabled || !currentRoom_sharedChatRoom

	const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			sendMessage()
		}
	}

	const sendMessage = () => {
		if (currentRoom_sharedChatRoom) {
			if (imagenUrl) {
				onSend("filetype:img" + imagenUrl)
			}

			if (videoUrl) {
				onSend("filetype:video" + videoUrl)
			}

			if (documentoUrl) {
				onSend("filetype:doc" + documentoUrl)
			}

			if (message) {
				onSend(message)
			}
		}

		set_videoUrl(null)
		set_documentoUrl(null)
		set_imagenUrl(null)
		set_message("")
	}

	var inputDescText = "Escribe un mensaje"
	if (inputDisabled && ug.userIsLogged) {
		inputDescText = "Selecciona una conversación para enviar mensajes"
	}

	return (
		<>
			<InputBase
				value={message}
				disabled={inputDisabled}
				onKeyUp={handleSend}
				onChange={(event) => set_message(event.target.value)}
				placeholder={inputDescText}
				startAdornment={
					<InputAdornment position="start">
						<SharedBasicIconify iconifyIcon="material-symbols:short-text" />
					</InputAdornment>
				}
				endAdornment={
					<Stack direction="row" spacing={1} sx={{ flexShrink: 0, mr: 1.5 }}>
						<IconButton disabled={inputDisabled} size="small" onClick={sendMessage}>
							<SharedBasicIconify iconifyIcon="material-symbols:send-outline" />
						</IconButton>

						<SharedFormUploadFile
							callerKey={ug.key}
							fieldName={"Video"}
							label={"Video"}
							fileUrl={videoUrl}
							awsFolder={globalAssets.awsFolders.chatVideos}
							handleOnChangeProp={set_videoUrl}
							maxSizeFile={10000000}
							justIcon={true}
							videoIcon={true}
							fileChanged={videoUrl !== null}
						/>

						<SharedFormUploadFile
							callerKey={ug.key}
							fieldName={"Documento"}
							label={"Documento"}
							fileUrl={documentoUrl}
							awsFolder={globalAssets.awsFolders.chatDocumentos}
							handleOnChangeProp={set_documentoUrl}
							maxSizeFile={10000000}
							justIcon={true}
							fileChanged={documentoUrl !== null}
						/>

						<SharedFormUploadImage
							callerKey={ug.key}
							fieldName={"Imagen"}
							label={"Imagen"}
							fileUrl={imagenUrl}
							awsFolder={globalAssets.awsFolders.chatImagenes}
							compressToSizeInMB={0.02}
							handleOnChangeProp={set_imagenUrl}
							hideHelperText={true}
							hideSubirImagen={true}
							isCircled={true}
							width={50}
							height={50}
						/>
					</Stack>
				}
				sx={{
					pl: 1,
					height: 56,
					flexShrink: 0,
					borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
					...sx,
				}}
				{...other}
			/>
		</>
	)
}
