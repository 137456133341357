import { combineReducers } from "redux"
import { bllEventos_Profile } from "./bllEventos_Profile"
import { bllEventos_Create } from "./bllEventos_Create"
import { bllEventos_Settings } from "./bllEventos_Settings"

export default combineReducers({
	Profile: bllEventos_Profile.reducerFunction,
	Settings: bllEventos_Settings.reducerFunction,
	CreateEvento: bllEventos_Create.reducerFunction,
})

export interface IEventos {
	Profile: bllEventos_Profile.IInterface
	Settings: bllEventos_Settings.IInterface
	CreateEvento: bllEventos_Create.IInterface
}
