import { combineReducers } from "redux"
import Globals, { IGlobalsServer } from "./GlobalsServer/bllGlobalsServer_Reducers"
import GlobalsUI, { IGlobalsUI } from "./GlobalsUI/bllGlobalsUI_Reducers"
import GlobalsGeneralData, { IGlobalsGeneralData } from "./GlobalsGeneralData/bllGlobalsGeneralData_Reducers"
import GlobalsUser, { IGlobalsUser } from "./GlobalsUser/bllGlobalsUser_Reducers"
import Users, { IUsers } from "./Users/bllUsers_Reducers"
import Personas, { IPersonas } from "./Personas/bllPersonas_Reducers"
import Notificaciones, { INotificaciones } from "./Notificaciones/bllNotificaciones_Reducers"
import Circuitos, { ICircuitos } from "./Circuitos/bllCircuitos_Reducers"
import Empresas, { IEmpresas } from "./Empresas/bllEmpresas_Reducers"
import Escuelas, { IEscuelas } from "./Escuelas/bllEscuelas_Reducers"
import Motoclubs, { IMotoclubs } from "./Motoclubs/bllMotoclubs_Reducers"
import Federaciones, { IFederaciones } from "./Federaciones/bllFederaciones_Reducers"
import Rodadas, { IRodadas } from "./Rodadas/bllRodadas_Reducers"
import Saludonnet, { ISaludonnet } from "./Saludonnet/bllSaludonnet_Reducers"
import Organizadores, { IOrganizadores } from "./Organizadores/bllOrganizadores_Reducers"
import Seguros, { ISeguros } from "./Seguros/bllSeguros_Reducers"
import AdminNotificaciones, { IAdminNotificaciones } from "./AdminNotificaciones/bllAdminNotificaciones_Reducers"
import Chat, { IChat } from "./Chat/bllChat_Reducers"
import ChatGrupo, { IChatGrupo } from "./ChatGrupo/bllChatGrupo_Reducers"
import Posteos, { IPosteos } from "./Posteos/bllPosteos_Reducers"
import Eventos, { IEventos } from "./Eventos/bllEventos_Reducers"
import GruposDePersonas, { IGruposDePersonas } from "./GruposDePersonas/bllGruposDePersonas_Reducers"

export default combineReducers({
	GlobalsServer: Globals,
	GlobalsUI: GlobalsUI,
	GlobalsGeneralData: GlobalsGeneralData,
	GlobalsUser: GlobalsUser,
	Users: Users,
	Personas: Personas,
	Notificaciones: Notificaciones,
	Circuitos: Circuitos,
	Empresas: Empresas,
	Escuelas: Escuelas,
	Motoclubs: Motoclubs,
	Federaciones: Federaciones,
	Organizadores: Organizadores,
	Rodadas: Rodadas,
	Saludonnet: Saludonnet,
	Seguros: Seguros,
	AdminNotificaciones: AdminNotificaciones,
	Chat: Chat,
	ChatGrupo: ChatGrupo,
	Posteos: Posteos,
	Eventos: Eventos,
	GruposDePersonas: GruposDePersonas,
})

export interface IState {
	GlobalsServer: IGlobalsServer
	GlobalsUI: IGlobalsUI
	GlobalsGeneralData: IGlobalsGeneralData
	GlobalsUser: IGlobalsUser
	Users: IUsers
	Personas: IPersonas
	Notificaciones: INotificaciones
	Circuitos: ICircuitos
	Empresas: IEmpresas
	Rodadas: IRodadas
	Saludonnet: ISaludonnet
	Escuelas: IEscuelas
	Motoclubs: IMotoclubs
	Federaciones: IFederaciones
	Organizadores: IOrganizadores
	Seguros: ISeguros
	AdminNotificaciones: IAdminNotificaciones
	Chat: IChat
	ChatGrupo: IChatGrupo
	Posteos: IPosteos
	Eventos: IEventos
	GruposDePersonas: IGruposDePersonas
}
