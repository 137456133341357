import { combineReducers } from "redux"
import { bllGruposDePersonas_Profile } from "./bllGruposDePersonas_Profile"
import { bllGruposDePersonas_Settings } from "./bllGruposDePersonas_Settings"
import { bllGruposDePersonas_Create } from "./bllGruposDePersonas_Create"

export default combineReducers({
	Profile: bllGruposDePersonas_Profile.reducerFunction,
	Settings: bllGruposDePersonas_Settings.reducerFunction,
	CreateGrupo: bllGruposDePersonas_Create.reducerFunction,
})

export interface IGruposDePersonas {
	Profile: bllGruposDePersonas_Profile.IInterface
	Settings: bllGruposDePersonas_Settings.IInterface
	CreateGrupo: bllGruposDePersonas_Create.IInterface
}
