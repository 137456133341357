import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilDate } from "../../utils/utilDate"
import { sharedEvento } from "./sharedEvento"
import { sharedGrupoDePersonasPersona } from "./sharedGrupoDePersonasPersona"

export namespace sharedGrupoDePersonas {
	export interface IShared {
		id: number
		nombre: string
		descripcion: string
		disclaimerHtml: string
		personas: sharedGrupoDePersonasPersona.IShared[]
		eventos: sharedEvento.IShared[]
		esPrivado: boolean | null
		soloAdministradoresPuedenChatear: boolean | null
		avatarURL: string
		coverURL: string
	}

	export const objectDefault: IShared = {
		id: 0,
		nombre: "",
		descripcion: "",
		disclaimerHtml: "",
		personas: [],
		eventos: [],
		esPrivado: false,
		soloAdministradoresPuedenChatear: false,
		avatarURL: globalAssets.staticPaths.grupoDePersonasAvatar1,
		coverURL: globalAssets.staticPaths.grupoDePersonasCover1,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
			personas:
				(apiObject.personas && sharedGrupoDePersonasPersona.getSharedList_FromApiObject(apiObject.personas)) || [],
			eventos: (apiObject.eventos && sharedEvento.getSharedList_FromApiObject(apiObject.eventos)) || [],
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.grupoDePersonasAvatar1,
			coverURL: apiObject.coverURL || globalAssets.staticPaths.grupoDePersonasCover1,
		}

		return res
	}

	export const listForRadioGroup = (
		sharedGrupoDePersonasList: IShared[]
	): globalInterfaces.IValueNumberLabelString[] => {
		return sharedGrupoDePersonasList.map((sharedGrupoDePersonas) => {
			return {
				value: sharedGrupoDePersonas.id,
				label: sharedGrupoDePersonas.nombre,
			}
		})
	}
}
