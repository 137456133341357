import { apiCallIG_Registrarse_ConfirmEmailConEmail } from "../../api/calls/IG/Registrarse/apiCallIG_Registrarse_ConfirmEmailConEmail"
import { utilRedux } from "../../utils/utilRedux"
import { apiCallInterface } from "../../api/apiCallInterface"
import { bllUsers_Login } from "./bllUsers_Login"

export namespace bllUsers_ConfirmEmail {
	//ET = enumTypes
	enum ET {
		setInitialState = "Users_ConfirmEmail_setInitialState",
		confirmEmailConEmail = "Users_ConfirmEmail_confirmEmailConEmail",
	}

	export interface IInterface {
		isEmailConfirmed: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			isEmailConfirmed: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.User_ConfirmEmail_ConfirmEmail:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function confirmEmailConEmail(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Registrarse_ConfirmEmailConEmail.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Registrarse_ConfirmEmailConEmail.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isEmailConfirmed">
				const updateObject: actionSubType = {
					isEmailConfirmed: true,
				}

				D(bllUsers_Login.setLoginRes(apiCallParams, apiCallRes.sharedLoginRes))

				utilRedux.TA(D, ET.confirmEmailConEmail, updateObject)
			}
		}
	}
}
