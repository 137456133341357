import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedCircuitoEstado } from "./sharedCircuitoEstado"
import { sharedCircuitoPista } from "./sharedCircuitoPista"
import { sharedCircuitoValoracion } from "./sharedCircuitoValoracion"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedCircuito {
	export interface IShared {
		id: number
		idPersonaCreadora: number
		nombre: string
		avatarURL: string
		coverURL: string
		direccion: sharedDireccion.IShared | null
		descripcionCorta: string
		descripcionLarga: string
		email: string
		telefonoMovil: string
		linkInstagram: string
		linkFacebook: string
		linkLinkedIn: string
		linkTwitter: string
		weatherWidgetUrl: string
		cantidadSeguidores: number
		nivelPilotaje: number | null
		longitudEnMetros: number | null
		tieneBanos: boolean | null
		tieneBar: boolean | null
		tieneFotografo: number | null
		tieneCronos: boolean | null
		precioAdulto: number | null
		precioNino: number | null
		horario: string
		diasParaEntrenar: string
		urlYoutube: string
		idEstado: number
		vehiculos: number[]
		pistas: sharedCircuitoPista.IShared[]
		valoraciones: sharedCircuitoValoracion.IShared[]
		valoracionInstalaciones: number | null
		valoracionTerreno: number | null
		valoracionRiegos: number | null
		valoracionSaltos: number | null
		valoracionSeguridad: number | null

		tieneWifi: boolean | null
		tieneGasolinera: boolean | null
		tieneSalaPrensa: boolean | null
		tieneSalaBriefing: boolean | null
		tieneZona4x4: boolean | null
		tieneHospital: boolean | null
		tieneCircuitoCerradoTV: boolean | null

		direccionDescripcion: string | null
		lat: string | null
		lng: string | null
	}

	export const defaultObject: IShared = {
		id: 0,
		idPersonaCreadora: 0,
		nombre: "",
		avatarURL: globalAssets.staticPaths.circuitoAvatar1,
		coverURL: globalAssets.staticPaths.circuitoCover1,
		direccion: null,
		descripcionCorta: "",
		descripcionLarga: "",
		email: "",
		telefonoMovil: "",
		linkInstagram: "",
		linkFacebook: "",
		linkLinkedIn: "",
		linkTwitter: "",
		weatherWidgetUrl: "",
		cantidadSeguidores: 0,
		nivelPilotaje: null,
		longitudEnMetros: null,
		tieneBanos: null,
		tieneBar: null,
		tieneFotografo: null,
		tieneCronos: null,
		precioAdulto: null,
		precioNino: null,
		horario: "",
		diasParaEntrenar: "",
		urlYoutube: "",
		idEstado: sharedCircuitoEstado.sharedEnum.creado.id,
		vehiculos: [],
		pistas: [],
		valoraciones: [],
		valoracionInstalaciones: null,
		valoracionTerreno: null,
		valoracionRiegos: null,
		valoracionSaltos: null,
		valoracionSeguridad: null,

		tieneWifi: null,
		tieneGasolinera: null,
		tieneSalaPrensa: null,
		tieneSalaBriefing: null,
		tieneZona4x4: null,
		tieneHospital: null,
		tieneCircuitoCerradoTV: null,

		direccionDescripcion: null,
		lat: null,
		lng: null,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			direccion: apiObject.direccion && sharedDireccion.getSharedOne_FromApiObject(apiObject.direccion),
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.circuitoAvatar1,
			coverURL: apiObject.coverURL || globalAssets.staticPaths.circuitoCover1,
			cantidadSeguidores: apiObject.cantidadSeguidores || 0,
			valoraciones:
				apiObject.valoraciones && sharedCircuitoValoracion.getSharedList_FromApiObject(apiObject.valoraciones),
		}

		// const res: ISharedCircuito = {
		// 	id: apiObject.id,
		// 	idPersonaCreadora: apiObject.idPersonaCreadora,
		// 	nombre: apiObject.nombre,
		// 	avatarURL: apiObject.avatarURL || globalAssetsStaticPaths.CircuitoAvatar1,
		// 	coverURL: apiObject.coverURL || globalAssetsStaticPaths.CircuitoCover1,
		// 	direccion: apiObject.direccion && getSharedDireccionFromApiObject(apiObject.direccion),
		// 	descripcionCorta: apiObject.descripcionCorta,
		// 	descripcionLarga: apiObject.descripcionLarga,
		// 	email: apiObject.email,
		// 	telefonoMovil: apiObject.telefonoMovil,
		// 	linkInstagram: apiObject.linkInstagram,
		// 	linkFacebook: apiObject.linkFacebook,
		// 	linkLinkedIn: apiObject.linkLinkedIn,
		// 	linkTwitter: apiObject.linkTwitter,
		// 	cantidadSeguidores: apiObject.cantidadSeguidores,
		// 	nivelPilotaje: apiObject.nivelPilotaje,
		// 	longitudEnMetros: apiObject.longitudEnMetros,
		// 	tieneBanos: apiObject.tieneBanos,
		// 	tieneBar: apiObject.tieneBar,
		// 	tieneFotografo: apiObject.tieneFotografo,
		// 	tieneCronos: apiObject.tieneCronos,
		// 	precioAdulto: apiObject.precioAdulto,
		// 	precioNino: apiObject.precioNino,
		// 	horario: apiObject.horario,
		// }

		return res
	}

	export const listForRadioGroup = (sharedCircuitoList: IShared[]): globalInterfaces.IValueNumberLabelString[] => {
		return sharedCircuitoList.map((sharedCircuito) => {
			return {
				value: sharedCircuito.id,
				label: sharedCircuito.nombre,
			}
		})
	}
}
